import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Account = {
  __typename?: 'Account';
  buildProjects: Array<BuildProject>;
  currentUser: User;
  id: Scalars['String'];
};

export type AccountManager = {
  __typename?: 'AccountManager';
  buildRegions: Array<BuildRegion>;
  consultCallUrl?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  projectManagerId: Scalars['String'];
  slackChannelId?: Maybe<Scalars['String']>;
  thumbnailMediaId?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedTs: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ActionAcknowledgement = {
  __typename?: 'ActionAcknowledgement';
  key: Scalars['String'];
  updatedTs: Scalars['DateTime'];
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  buildRegions?: Maybe<Array<BuildRegion>>;
  businessName?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  phone?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  updatedTs: Scalars['DateTime'];
  zipcode: Scalars['String'];
};

export type AddressPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<AddressesSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum AddressesSortByEnum {
  AddressLine_1 = 'ADDRESS_LINE_1',
  AddressLine_2 = 'ADDRESS_LINE_2',
  BusinessName = 'BUSINESS_NAME',
  City = 'CITY',
  CreatedAt = 'CREATED_AT',
  State = 'STATE',
  UpdatedAt = 'UPDATED_AT',
  Zipcode = 'ZIPCODE'
}

export enum ApprovalStatus {
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
  Paused = 'PAUSED'
}

export type AssignAccountManagerInput = {
  buildProjectId: Scalars['String'];
  leadCaptureEmail?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type BriefElementsExportData = {
  __typename?: 'BriefElementsExportData';
  address: Scalars['String'];
  tableColumns: Array<BriefElementsExportRow>;
};

export type BriefElementsExportRow = {
  __typename?: 'BriefElementsExportRow';
  name: Scalars['String'];
  quantity: Scalars['Float'];
  totalCost: Scalars['String'];
  vendor: Scalars['String'];
};

export type BriefHeaderInformation = {
  __typename?: 'BriefHeaderInformation';
  address: Scalars['String'];
  contractorNotes?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  revisionName?: Maybe<Scalars['String']>;
};

export type BriefMaterialExportData = {
  __typename?: 'BriefMaterialExportData';
  address: Scalars['String'];
  tableColumns: Array<BriefMaterialExportRow>;
};

export type BriefMaterialExportRow = {
  __typename?: 'BriefMaterialExportRow';
  color: Scalars['String'];
  materialName: Scalars['String'];
  pattern: Scalars['String'];
  quantity: Scalars['Float'];
  size: Scalars['String'];
  vendor: Scalars['String'];
};

export type BriefMedia = {
  __typename?: 'BriefMedia';
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  path: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type BriefPlantExportData = {
  __typename?: 'BriefPlantExportData';
  address: Scalars['String'];
  tableColumns: Array<BriefPlantExportRow>;
};

export type BriefPlantExportRow = {
  __typename?: 'BriefPlantExportRow';
  commonName: Scalars['String'];
  containerSize: Scalars['String'];
  lighting: Scalars['String'];
  quantity: Scalars['Float'];
  scientificName: Scalars['String'];
  type: Scalars['String'];
  waterNeeds: Scalars['String'];
};

export type BuildProject = {
  __typename?: 'BuildProject';
  account: Account;
  accountId: Scalars['String'];
  accountManager?: Maybe<AccountManager>;
  accountManagerId?: Maybe<Scalars['String']>;
  cards?: Maybe<Array<ProjectCard>>;
  createdTs: Scalars['DateTime'];
  designAssignment?: Maybe<DesignAssignment>;
  estimates: Array<Estimate>;
  id: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  pipefyClientCardId?: Maybe<Scalars['String']>;
  proFacingNotes?: Maybe<Scalars['String']>;
  projectBudgetEstimate?: Maybe<Array<Scalars['Float']>>;
  projectCards?: Maybe<Array<ResolvedProjectCard>>;
  projectElements: Array<ProjectElement>;
  surveyResponses: Array<SurveyResponse>;
  updatedTs: Scalars['DateTime'];
};


export type BuildProjectEstimatesArgs = {
  input?: InputMaybe<FindAllEstimatesInput>;
};


export type BuildProjectProjectCardsArgs = {
  input?: InputMaybe<ResolveBuildProjectPipefyCardsInput>;
};

export type BuildProjectPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<BuildProjectSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum BuildProjectSortByEnum {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type BuildRegion = {
  __typename?: 'BuildRegion';
  accountManager?: Maybe<AccountManager>;
  accountManagerId?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  areaGeometry: Scalars['String'];
  contractorId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  internalName: Scalars['String'];
  isDefault: Scalars['Boolean'];
  lastUpdatedBy: Scalars['String'];
  maxLat: Scalars['Float'];
  maxLng: Scalars['Float'];
  minLat: Scalars['Float'];
  minLng: Scalars['Float'];
  updatedTs: Scalars['DateTime'];
};

export type Card = {
  __typename?: 'Card';
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type CardField = {
  __typename?: 'CardField';
  field?: Maybe<PipefyField>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type CardPhase = {
  __typename?: 'CardPhase';
  done: Scalars['Boolean'];
  fieldConditions?: Maybe<Array<PhaseFieldCondition>>;
  fields?: Maybe<Array<PhaseField>>;
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CityProjectElement = {
  __typename?: 'CityProjectElement';
  city: Scalars['String'];
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  popularity: Scalars['Float'];
  projectElement: ProjectElement;
  updatedTs: Scalars['DateTime'];
  url: Scalars['String'];
};

export type ClientCard = {
  __typename?: 'ClientCard';
  fields?: Maybe<Array<CardField>>;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type Contractor = {
  __typename?: 'Contractor';
  abilities: Array<Scalars['String']>;
  acceptingWork: Scalars['Boolean'];
  approvalStatus: Scalars['String'];
  approved: Scalars['Boolean'];
  businessName: Scalars['String'];
  city: Scalars['String'];
  closeRate?: Maybe<Scalars['Float']>;
  coordinates?: Maybe<Coordinates>;
  createdAt: Scalars['String'];
  currentBuildRegionId: Scalars['String'];
  dashboardAccessEmails?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  initialBuildRegionId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  matchedUsers: Array<UserProfileEnhanced>;
  maxSuccessBudget?: Maybe<Scalars['Float']>;
  minSuccessBudget?: Maybe<Scalars['Float']>;
  notApprovedStatusReason?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paused: Scalars['Boolean'];
  pausedAt?: Maybe<Scalars['String']>;
  pausedStatusReason?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pipefyContractorCardId?: Maybe<Scalars['Float']>;
  pointOfContactEmail: Scalars['String'];
  pointOfContactName?: Maybe<Scalars['String']>;
  pointOfContactPhone?: Maybe<Scalars['String']>;
  preferred: Scalars['Boolean'];
  profile: ContractorProfile;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  unpausedAt?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};


export type ContractorMatchedUsersArgs = {
  userProfileQuery: Scalars['String'];
};

export enum ContractorAttribute {
  BipocOwned = 'BIPOC_OWNED',
  Bonded = 'BONDED',
  BookingFast = 'BOOKING_FAST',
  EcoFriendly = 'ECO_FRIENDLY',
  FamilyOwned = 'FAMILY_OWNED',
  FemaleFounded = 'FEMALE_FOUNDED',
  FreeEstimates = 'FREE_ESTIMATES',
  Insured = 'INSURED',
  Licensed = 'LICENSED',
  OwnerOperated = 'OWNER_OPERATED',
  PremiumExperience = 'PREMIUM_EXPERIENCE',
  ResponsiveCommunication = 'RESPONSIVE_COMMUNICATION',
  SuperPro = 'SUPER_PRO',
  ValuePro = 'VALUE_PRO',
  VeteranOwned = 'VETERAN_OWNED',
  YardzenApproved = 'YARDZEN_APPROVED',
  YearsInBusiness = 'YEARS_IN_BUSINESS'
}

export type ContractorDocument = {
  __typename?: 'ContractorDocument';
  confirmationLink?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  kind: Scalars['String'];
  licenseNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type ContractorImage = {
  __typename?: 'ContractorImage';
  id: Scalars['String'];
  uri: Scalars['String'];
};

export type ContractorProfile = {
  __typename?: 'ContractorProfile';
  aboutSummary?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  attributes?: Maybe<Array<ContractorAttribute>>;
  budgetRangeLowerBound?: Maybe<Scalars['Int']>;
  budgetRangeUpperBound?: Maybe<Scalars['Int']>;
  businessName?: Maybe<Scalars['String']>;
  calendlyUrl?: Maybe<Scalars['String']>;
  contractor: Contractor;
  contractorId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  documents: Array<ContractorDocument>;
  email?: Maybe<Scalars['String']>;
  galleryImages: Array<ContractorImage>;
  googleUrl?: Maybe<Scalars['String']>;
  headshotMediaUrl?: Maybe<Scalars['String']>;
  isTest?: Maybe<Scalars['Boolean']>;
  licenseExpirationDate?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  loginHistory?: Maybe<Array<LoginHistory>>;
  maxSuccessBudget?: Maybe<Scalars['Int']>;
  media?: Maybe<Array<Media>>;
  minSuccessBudget?: Maybe<Scalars['Int']>;
  projectDigestFrequency?: Maybe<ProjectDigestFrequency>;
  promotionalMessage?: Maybe<Scalars['String']>;
  promotionalMessageEndDate?: Maybe<Scalars['DateTime']>;
  promotionalMessageStartDate?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Float']>;
  reviews?: Maybe<Array<ContractorReview>>;
  revisions: Array<Revision>;
  stripeConnectedAccountId?: Maybe<Scalars['String']>;
  thumbnailMediaUrl?: Maybe<Scalars['String']>;
  updatedTs: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  videos: Array<ContractorImage>;
  whyYardzenLovesSummary?: Maybe<Scalars['String']>;
  yardAiEnabled?: Maybe<Scalars['Boolean']>;
  yearsInBusiness?: Maybe<Scalars['Int']>;
  yelpUrl?: Maybe<Scalars['String']>;
};


export type ContractorProfileLoginHistoryArgs = {
  pagination?: InputMaybe<LoginHistoryPaginator>;
};

export type ContractorReview = {
  __typename?: 'ContractorReview';
  author: Scalars['String'];
  contractorId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  reviewedTs: Scalars['DateTime'];
  source: ContractorReviewSource;
  summary: Scalars['String'];
  updatedTs: Scalars['DateTime'];
};

export enum ContractorReviewSource {
  Google = 'GOOGLE',
  Homeadvisor = 'HOMEADVISOR',
  Houzz = 'HOUZZ',
  Yardzen = 'YARDZEN',
  Yelp = 'YELP'
}

export type Conversation = {
  __typename?: 'Conversation';
  accountManager?: Maybe<AccountManager>;
  archived: Scalars['Boolean'];
  archivedTs?: Maybe<Scalars['DateTime']>;
  conversationParticipants: Array<ConversationParticipant>;
  createdTs: Scalars['DateTime'];
  customerInactive: Scalars['Boolean'];
  id: Scalars['String'];
  latestMessage?: Maybe<Message>;
  sid: Scalars['String'];
  sortByTimestamp?: Maybe<Scalars['DateTime']>;
  totalConversationCount?: Maybe<Scalars['Float']>;
  updatedTs: Scalars['DateTime'];
};

export type ConversationParticipant = {
  __typename?: 'ConversationParticipant';
  contractor?: Maybe<ContractorProfile>;
  contractorId?: Maybe<Scalars['String']>;
  conversationId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  cursor?: Maybe<ConversationParticipantCursor>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role: ConversationRole;
  twilioId?: Maybe<Scalars['String']>;
  updatedTs: Scalars['DateTime'];
  userProfile?: Maybe<UserProfile>;
  userProfileId?: Maybe<Scalars['String']>;
};

export type ConversationParticipantCursor = {
  __typename?: 'ConversationParticipantCursor';
  conversationParticipantId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  messageId: Scalars['String'];
  updatedTs: Scalars['DateTime'];
};

export enum ConversationRole {
  Admin = 'ADMIN',
  Contractor = 'CONTRACTOR',
  Customer = 'CUSTOMER'
}

export enum ConversationsPaginatorSortByEnum {
  CreatedTs = 'CREATED_TS',
  LastMessageCreatedTs = 'LAST_MESSAGE_CREATED_TS'
}

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CreateAccountInput = {
  userId: Scalars['String'];
};

export type CreateAccountManagerInput = {
  buildRegionIds: Array<Scalars['String']>;
  consultCallUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  projectManagerId: Scalars['String'];
  slackChannelId?: InputMaybe<Scalars['String']>;
  thumbnailMediaId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type CreateActionAcknowledgementInput = {
  key: Scalars['String'];
};

export type CreateAddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  phone?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipcode: Scalars['String'];
};

export type CreateBuildProjectInput = {
  accountId: Scalars['String'];
  members: Array<Scalars['String']>;
  projectElements?: InputMaybe<Array<ProjectElementsBatchInput>>;
};

export type CreateBuildRegionInput = {
  accountManagerId?: InputMaybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  areaGeometry: Scalars['String'];
  contractorId: Scalars['String'];
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  isDefault: Scalars['Boolean'];
  lastUpdatedBy: Scalars['String'];
};

export type CreateBuildStudioUserInput = {
  accountManagerId?: InputMaybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  email: Scalars['String'];
  firebaseProjectId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  phone?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  userId: Scalars['String'];
  zipcode: Scalars['String'];
};

export type CreateContractorDocumentInput = {
  confirmationLink?: InputMaybe<Scalars['String']>;
  contractorId: Scalars['String'];
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  kind: Scalars['String'];
  licenseNumber?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<MediaSource>;
};

export type CreateContractorInput = {
  abilities?: InputMaybe<Array<Scalars['String']>>;
  acceptingWork: Scalars['Boolean'];
  approved: Scalars['Boolean'];
  businessName: Scalars['String'];
  city: Scalars['String'];
  closeRate?: InputMaybe<Scalars['Float']>;
  coordinates?: InputMaybe<InputCoordinates>;
  currentBuildRegionId?: InputMaybe<Scalars['String']>;
  dashboardAccessEmails?: InputMaybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locations?: InputMaybe<Array<Scalars['String']>>;
  maxSuccessBudget?: InputMaybe<Scalars['Float']>;
  minSuccessBudget?: InputMaybe<Scalars['Float']>;
  notes: Scalars['String'];
  paused: Scalars['Boolean'];
  phone?: InputMaybe<Scalars['String']>;
  pipefyContractorCardId?: InputMaybe<Scalars['Float']>;
  pointOfContactEmail: Scalars['String'];
  pointOfContactName: Scalars['String'];
  pointOfContactPhone?: InputMaybe<Scalars['String']>;
  preferred: Scalars['Boolean'];
  state: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreateContractorMediaInput = {
  contractorId: Scalars['String'];
  id: Scalars['String'];
  mimeType: Scalars['String'];
  order?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<MediaSource>;
};

export type CreateContractorProfileInput = {
  aboutSummary?: InputMaybe<Scalars['String']>;
  active: Scalars['Boolean'];
  attributes?: InputMaybe<Array<ContractorAttribute>>;
  budgetRangeLowerBound?: InputMaybe<Scalars['Int']>;
  budgetRangeUpperBound?: InputMaybe<Scalars['Int']>;
  businessName?: InputMaybe<Scalars['String']>;
  calendlyUrl?: InputMaybe<Scalars['String']>;
  contractorId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  googleUrl?: InputMaybe<Scalars['String']>;
  headshotMediaUrl?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  licenseExpirationDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  maxSuccessBudget?: InputMaybe<Scalars['Int']>;
  minSuccessBudget?: InputMaybe<Scalars['Int']>;
  projectDigestFrequency?: InputMaybe<ProjectDigestFrequency>;
  promotionalMessage?: InputMaybe<Scalars['String']>;
  promotionalMessageEndDate?: InputMaybe<Scalars['DateTime']>;
  promotionalMessageStartDate?: InputMaybe<Scalars['DateTime']>;
  rating?: InputMaybe<Scalars['Float']>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  thumbnailMediaUrl?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  whyYardzenLovesSummary?: InputMaybe<Scalars['String']>;
  yardAiEnabled?: InputMaybe<Scalars['Boolean']>;
  yearsInBusiness?: InputMaybe<Scalars['Int']>;
  yelpUrl?: InputMaybe<Scalars['String']>;
};

export type CreateContractorReviewInput = {
  author: Scalars['String'];
  contractorId: Scalars['String'];
  reviewedTs: Scalars['DateTime'];
  source: ContractorReviewSource;
  summary: Scalars['String'];
};

export type CreateConversationInput = {
  archivedTs?: InputMaybe<Scalars['DateTime']>;
  conversationParticipants: Array<ParticipantInput>;
  name: Scalars['String'];
  sortByTimestamp?: InputMaybe<Scalars['DateTime']>;
  totalConversationCount?: InputMaybe<Scalars['Float']>;
};

export type CreateCurrentUserTermsOfServiceInput = {
  dateOfAgree: Scalars['DateTime'];
  tosAgreed: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type CreateDesignOnboardingInput = {
  additionalNotes?: InputMaybe<Scalars['String']>;
  buildProjectId: Scalars['String'];
  dimensionsLength: Scalars['Float'];
  dimensionsWidth: Scalars['Float'];
  inspirationLinks?: InputMaybe<Array<Scalars['String']>>;
  totalSquareFootage: Scalars['Float'];
};

export type CreateEstimateInput = {
  buildProjectId: Scalars['String'];
  clonedFromEstimateId?: InputMaybe<Scalars['String']>;
  contractorDeposit?: InputMaybe<Scalars['Int']>;
  contractorId: Scalars['String'];
  evaluationNotes?: InputMaybe<Scalars['String']>;
  expectedEndDate?: InputMaybe<Scalars['DateTime']>;
  expectedStartDate?: InputMaybe<Scalars['DateTime']>;
  platformFee?: InputMaybe<Scalars['Int']>;
  proEstimateNumber?: InputMaybe<Scalars['String']>;
  proTermDetails?: InputMaybe<Scalars['String']>;
  sentToClient: Scalars['Boolean'];
  sentToClientBy?: InputMaybe<Scalars['String']>;
  sentToClientTs?: InputMaybe<Scalars['DateTime']>;
  version: Scalars['Int'];
};

export type CreateEstimatePaymentLineItemInput = {
  estimateId: Scalars['String'];
  name: Scalars['String'];
  paymentDue: Scalars['String'];
  totalAmountDue: Scalars['Int'];
};

export type CreateEstimateWorkLineItemInput = {
  clientFeedbackNotes?: InputMaybe<Scalars['String']>;
  clientFeedbackTs?: InputMaybe<Scalars['DateTime']>;
  clonedFromEstimateWorkLineItemId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  estimateId: Scalars['String'];
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
  totalCost: Scalars['Int'];
};

export type CreateInvoiceInput = {
  accountId: Scalars['String'];
  applicationFeeAmount?: InputMaybe<Scalars['Int']>;
  clientNotes?: InputMaybe<Scalars['String']>;
  connectedAccountId?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  estimateId?: InputMaybe<Scalars['String']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  lastPaymentAttemptedTs?: InputMaybe<Scalars['DateTime']>;
  lineItems: Array<NestedCreateInvoiceLineItemInput>;
  name?: InputMaybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  paymentDueTs?: InputMaybe<Scalars['DateTime']>;
  paymentMilestone?: InputMaybe<InvoicePaymentMilestone>;
  proInvoiceReference?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  receiptEmail?: InputMaybe<Scalars['String']>;
  sendGhostMessage?: InputMaybe<Scalars['Boolean']>;
  totalAmount: Scalars['Int'];
  yzFeeRate?: InputMaybe<Scalars['String']>;
};

export type CreateLoginHistoryInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateMatchInput = {
  buildProjectId: Scalars['String'];
  contractorId: Scalars['String'];
  pipefyDtbCardId?: InputMaybe<Scalars['String']>;
  rank: Scalars['Int'];
  totalContractValue?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type CreatePipefyAttachmentUrlInput = {
  fileName: Scalars['String'];
};

export type CreatePipefyCardInput = {
  buildProjectId: Scalars['String'];
  contractorId: Scalars['String'];
  overrideIsTestAccount: Scalars['Boolean'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateRevisionInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  shareMediaLink?: InputMaybe<Scalars['String']>;
};

export type CreateStyleProfileInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateStyleProfileLikeImageInput = {
  galleryImageId?: InputMaybe<Scalars['String']>;
  galleryImageIds?: InputMaybe<Array<Scalars['String']>>;
  styleProfileId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateSurveyInput = {
  surveyDesc: Scalars['String'];
  surveyKey: Scalars['String'];
  version: Scalars['Float'];
};

export type CreateSurveyQuestionInput = {
  format: Scalars['String'];
  index: Scalars['Float'];
  questionKey: Scalars['String'];
  required: Scalars['Boolean'];
  section: Scalars['Float'];
  surveyId: Scalars['String'];
  text: Scalars['String'];
};

export type CreateSurveyResponseInput = {
  buildProjectId: Scalars['String'];
  completed: Scalars['Boolean'];
  surveyId: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateUserInput = {
  accountId: Scalars['String'];
  isLeadCapture: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type CreateUserProfileInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadZipcode?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  optIn?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  testAccount?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type CreateUserTermsOfServiceInput = {
  dateOfAgree: Scalars['DateTime'];
  tosAgreed: Scalars['Boolean'];
  typeOfTos: Scalars['String'];
  userId: Scalars['String'];
};

export type DateFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteContractorInput = {
  id: Scalars['String'];
};

export type DesignAssignment = {
  __typename?: 'DesignAssignment';
  designPackage: Scalars['String'];
  info: Scalars['String'];
  status: Scalars['String'];
};

export type DesignOnboardMessageSubmissionInput = {
  message: Scalars['String'];
  userProfileId: Scalars['String'];
};

export type DesignOnboarding = {
  __typename?: 'DesignOnboarding';
  additionalNotes?: Maybe<Scalars['String']>;
  buildProject: BuildProject;
  buildProjectId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  dimensionsLength: Scalars['Float'];
  dimensionsWidth: Scalars['Float'];
  id: Scalars['String'];
  inspirationLinks?: Maybe<Array<Scalars['String']>>;
  inspirationPhotos: Array<Media>;
  propertyPhotos: Array<Media>;
  totalSquareFootage: Scalars['Float'];
  updatedTs: Scalars['DateTime'];
};

export enum DesignPackage {
  CurbAppeal = 'CURB_APPEAL',
  EntireYardStarter = 'ENTIRE_YARD_STARTER',
  FrontOrBackYardStarter = 'FRONT_OR_BACK_YARD_STARTER',
  FrontYard = 'FRONT_YARD',
  FullYard = 'FULL_YARD',
  PartialYard = 'PARTIAL_YARD',
  SmallSpace = 'SMALL_SPACE',
  WholeHome = 'WHOLE_HOME'
}

export type EnsureAccountSetupInput = {
  accountId?: InputMaybe<Scalars['String']>;
  createUserProfileInput?: InputMaybe<CreateUserProfileInput>;
  isLeadCapture?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type EnsureAccountSetupResponse = {
  __typename?: 'EnsureAccountSetupResponse';
  accountId: Scalars['String'];
  userId: Scalars['String'];
};

export type Estimate = {
  __typename?: 'Estimate';
  buildProject: BuildProject;
  buildProjectId: Scalars['String'];
  clonedFromEstimateId?: Maybe<Scalars['String']>;
  contractor: Contractor;
  contractorDeposit?: Maybe<Scalars['Int']>;
  contractorId: Scalars['String'];
  contractorProfile: ContractorProfile;
  conversation?: Maybe<Conversation>;
  createdTs: Scalars['DateTime'];
  estimateBidFiles: Array<Media>;
  evaluationNotes?: Maybe<Scalars['String']>;
  expectedEndDate?: Maybe<Scalars['DateTime']>;
  expectedStartDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  paymentLineItems: Array<EstimatePaymentLineItem>;
  platformFee?: Maybe<Scalars['Int']>;
  proEstimateNumber?: Maybe<Scalars['String']>;
  proTermDetails?: Maybe<Scalars['String']>;
  proTermDocuments?: Maybe<Array<Media>>;
  sentToClient: Scalars['Boolean'];
  sentToClientBy?: Maybe<Scalars['String']>;
  sentToClientTs?: Maybe<Scalars['DateTime']>;
  updatedTs: Scalars['DateTime'];
  version: Scalars['Int'];
  workLineItems: Array<EstimateWorkLineItem>;
};


export type EstimatePaymentLineItemsArgs = {
  pagination?: InputMaybe<FindAllEstimatePaymentLineItemsPaginator>;
};


export type EstimateWorkLineItemsArgs = {
  pagination?: InputMaybe<FindAllEstimateWorkLineItemsPaginator>;
};

export type EstimatePaymentLineItem = {
  __typename?: 'EstimatePaymentLineItem';
  createdTs: Scalars['DateTime'];
  estimateId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  paymentDue: Scalars['String'];
  totalAmountDue: Scalars['Int'];
  updatedTs: Scalars['DateTime'];
};

export enum EstimatePaymentLineItemsPaginatorSortByEnum {
  CreatedTs = 'CREATED_TS',
  TotalCost = 'TOTAL_COST',
  UpdatedTs = 'UPDATED_TS'
}

export type EstimateWorkLineItem = {
  __typename?: 'EstimateWorkLineItem';
  clientFeedbackNotes?: Maybe<Scalars['String']>;
  clientFeedbackTs?: Maybe<Scalars['DateTime']>;
  clonedFromEstimateWorkLineItemId?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  description: Scalars['String'];
  estimateId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
  totalCost: Scalars['Int'];
  updatedTs: Scalars['DateTime'];
};

export enum EstimateWorkLineItemsPaginatorSortByEnum {
  CreatedTs = 'CREATED_TS',
  TotalCost = 'TOTAL_COST',
  UpdatedTs = 'UPDATED_TS'
}

export enum EstimatesPaginatorSortByEnum {
  CreatedTs = 'CREATED_TS',
  UpdatedTs = 'UPDATED_TS'
}

export type FetchAdminConversationsInput = {
  userProfileId: Scalars['String'];
};

export type FieldCondition = {
  __typename?: 'FieldCondition';
  expressions: Array<FieldConditionExpression>;
};

export type FieldConditionAction = {
  __typename?: 'FieldConditionAction';
  actionId: Scalars['String'];
  phaseField?: Maybe<PhaseField>;
  whenEvaluator: Scalars['Boolean'];
};

export type FieldConditionExpression = {
  __typename?: 'FieldConditionExpression';
  field_address: Scalars['String'];
  operation: Scalars['String'];
  value: Scalars['String'];
};

export type FindAllAddressesInput = {
  addressLine1?: InputMaybe<StringFilter>;
  addressLine2?: InputMaybe<StringFilter>;
  businessName?: InputMaybe<StringFilter>;
  city?: InputMaybe<StringFilter>;
  createdTs?: InputMaybe<DateFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  pagination?: InputMaybe<AddressPaginator>;
  state?: InputMaybe<StringFilter>;
  updatedTs?: InputMaybe<DateFilter>;
  zipcode?: InputMaybe<StringFilter>;
};

export type FindAllBuildProjectsInput = {
  accountId: Scalars['String'];
  pagination?: InputMaybe<BuildProjectPaginator>;
};

export type FindAllBuildRegionInput = {
  accountManagerId?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  contractorId?: InputMaybe<Scalars['String']>;
  /**
   *
   *     The geographical coordinates of the build region ordered [longitude, latitude].
   *
   */
  coordinates?: InputMaybe<Array<Scalars['Float']>>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
};

export type FindAllContractorReviewsInput = {
  author?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
};

export type FindAllContractorsInput = {
  acceptingWork?: InputMaybe<Scalars['Boolean']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  businessName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<SortOrder>;
  orderBy?: InputMaybe<OrderBy>;
  paused?: InputMaybe<Scalars['Boolean']>;
  startAfterId?: InputMaybe<Scalars['String']>;
  startAfterValue?: InputMaybe<Scalars['String']>;
};

export type FindAllConversationParticipantsInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<ConversationRole>;
  userProfileId?: InputMaybe<Scalars['String']>;
};

export type FindAllConversationsInput = {
  accountManagerId?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  contractorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  paginator?: InputMaybe<FindConversationsPaginator>;
  readMessagesSearchTerm?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<ConversationRole>;
  searchTerm?: InputMaybe<Scalars['String']>;
  unreadOnly?: InputMaybe<Scalars['Boolean']>;
  userProfileId?: InputMaybe<Scalars['String']>;
};

export type FindAllEstimatePaymentLineItemsInput = {
  estimateId?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<FindAllEstimatePaymentLineItemsPaginator>;
};

export type FindAllEstimatePaymentLineItemsPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<EstimatePaymentLineItemsPaginatorSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type FindAllEstimateWorkLineItemsInput = {
  estimateId?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<FindAllEstimateWorkLineItemsPaginator>;
};

export type FindAllEstimateWorkLineItemsPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<EstimateWorkLineItemsPaginatorSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type FindAllEstimatesInput = {
  buildProjectId?: InputMaybe<Scalars['String']>;
  clientSearchTerm?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
  paginator?: InputMaybe<FindAllEstimatesPaginator>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FindAllEstimatesPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<EstimatesPaginatorSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type FindAllGalleryImageInput = {
  galleryFilters?: InputMaybe<GalleryFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  styleProfileIdFilter?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FindAllGhostedConversationsPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<GhostedConversationsPaginatorSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type FindAllLoginHistoryInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<LoginHistoryPaginator>;
  userAgent?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FindAllMarketsInput = {
  displayName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  internalName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<MarketOrder>;
  orderBy?: InputMaybe<MarketOrderBy>;
  startAfterId?: InputMaybe<Scalars['String']>;
  startAfterValue?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type FindAllMatchesInput = {
  clientSearchTerm?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortByField?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FindAllMessagesInput = {
  author?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['String']>;
};

export type FindAllProjectElementsByCityInput = {
  city: Scalars['String'];
};

export type FindAllRevisionsInput = {
  contractorId: Scalars['String'];
  pagination?: InputMaybe<RevisionPagination>;
};

export type FindAllStyleProfileInput = {
  pagination?: InputMaybe<StyleProfilePaginator>;
  userId?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type FindAllUserTermsOfServiceInput = {
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  typeOfTos?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type FindAllUsersInput = {
  accountId: Scalars['String'];
  pagination?: InputMaybe<UsersPaginator>;
};

export type FindBuildProjectInput = {
  id: Scalars['String'];
  shareLinkId?: InputMaybe<Scalars['String']>;
};

export type FindByStyleProfileInput = {
  userId: Scalars['String'];
};

export type FindConversationsPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<ConversationsPaginatorSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type FindInvoicesInput = {
  accountId?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
  /** Only used when contractorId is present. Returns invoices only for clients the contractor has an active match with */
  enforceContractorMatches?: InputMaybe<Scalars['Boolean']>;
  lastPaymentAttemptedTs?: InputMaybe<DateFilter>;
  paid?: InputMaybe<Scalars['Boolean']>;
  paymentMilestone?: InputMaybe<InvoicePaymentMilestone>;
  pendingPayback?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
  voided?: InputMaybe<Scalars['Boolean']>;
};

export type FindMatchesInput = {
  accountId?: InputMaybe<Scalars['String']>;
  buildProjectId?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
  forceLegacyMatch?: InputMaybe<Scalars['Boolean']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  leadCaptureEmail?: InputMaybe<Scalars['String']>;
  readCommitted?: InputMaybe<Scalars['Boolean']>;
  rematch?: InputMaybe<Scalars['Boolean']>;
};

export type FindOneAccountInput = {
  accountId: Scalars['String'];
};

export type FindOneBuildRegionInput = {
  id?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
};

export type FindOneContractorInput = {
  id: Scalars['String'];
};

export type FindOneConversationInput = {
  id?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
};

export type FindOneDesignOnboardingInput = {
  buildProjectId: Scalars['String'];
};

export type FindOneGalleryImageInput = {
  airtableId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
};

export type FindOneUserProfileInput = {
  userId: Scalars['String'];
};

export type FindOneUserTermsOfServiceInput = {
  id: Scalars['String'];
};

export type FindProjectElementsInput = {
  buildProjectId?: InputMaybe<Scalars['String']>;
};

export type FindSurveyInput = {
  surveyKey: Scalars['String'];
};

export type FindSurveyResponseInput = {
  buildProjectId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  shareLinkId?: InputMaybe<Scalars['String']>;
  surveyId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Scalars['Float']>;
};

export type GalleryFilter = {
  amenities?: InputMaybe<Array<Scalars['String']>>;
  region?: InputMaybe<Array<Scalars['String']>>;
  styles?: InputMaybe<Array<Scalars['String']>>;
  yardAttributes?: InputMaybe<Array<Scalars['String']>>;
};

export type GalleryImage = {
  __typename?: 'GalleryImage';
  airtableId: Scalars['Float'];
  amenities?: Maybe<Array<Scalars['String']>>;
  city: Scalars['String'];
  designPackage: Scalars['String'];
  id: Scalars['String'];
  state: Scalars['String'];
  styles?: Maybe<Array<Scalars['String']>>;
  url: Scalars['String'];
};

export type GetGhostedConversationsInput = {
  accountManagerId?: InputMaybe<Scalars['String']>;
  /** null = any */
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  contractorId?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<FindAllGhostedConversationsPaginator>;
};

export type GetLatestConversationMessageInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  conversationIds: Array<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type GetNextRenderFeedbackSetInput = {
  designPackages?: InputMaybe<Array<DesignPackage>>;
  elements?: InputMaybe<Array<YardElement>>;
  featured?: InputMaybe<Scalars['Boolean']>;
  liked?: InputMaybe<Scalars['Boolean']>;
  likedFrom?: InputMaybe<Scalars['String']>;
  page: Scalars['Float'];
  regions?: InputMaybe<Array<Region>>;
  segmentId: Scalars['String'];
  styles?: InputMaybe<Array<Style>>;
  yardLocation?: InputMaybe<Array<YardDesignLocation>>;
  zip?: InputMaybe<Scalars['String']>;
};

export type GetProjectBriefMediaInput = {
  revisionId: Scalars['String'];
  shareLinkId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type GetShareLinkId = {
  __typename?: 'GetShareLinkId';
  shareLinkId: Scalars['String'];
};

export type GetUnreadMessagesInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  userProfileId?: InputMaybe<Scalars['String']>;
};

export type GhostedConversation = {
  __typename?: 'GhostedConversation';
  acknowledged: Scalars['Boolean'];
  conversationId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  ghosted: Scalars['Boolean'];
  ghostedParticipant: ConversationParticipant;
  ghostedParticipantId: Scalars['String'];
  ghostingParticipant: ConversationParticipant;
  ghostingParticipantId: Scalars['String'];
  id: Scalars['String'];
  messageIds: Array<Scalars['String']>;
  messages: Array<Message>;
  reason: Scalars['String'];
};

export enum GhostedConversationsPaginatorSortByEnum {
  CreatedTs = 'CREATED_TS'
}

export type InitPaymentDto = {
  invoiceId: Scalars['String'];
};

export type InputCoordinates = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Invoice = {
  __typename?: 'Invoice';
  accountId: Scalars['String'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  clientNotes?: Maybe<Scalars['String']>;
  connectedAccountId?: Maybe<Scalars['String']>;
  contractor?: Maybe<Contractor>;
  contractorId?: Maybe<Scalars['String']>;
  contractorProfile?: Maybe<ContractorProfile>;
  conversationId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<UserProfile>;
  createdByYzAdmin?: Maybe<Scalars['Boolean']>;
  createdTs: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  estimate?: Maybe<Estimate>;
  estimateId?: Maybe<Scalars['String']>;
  estimateInvoiceNumber?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  incrementalInvoiceNumber?: Maybe<Scalars['Int']>;
  internalNotes?: Maybe<Scalars['String']>;
  isRefunded: Scalars['Boolean'];
  lastPaymentAttemptedTs?: Maybe<Scalars['DateTime']>;
  lineItems: Array<InvoiceLineItem>;
  name: Scalars['String'];
  paid: Scalars['Boolean'];
  paybackTransfers?: Maybe<Array<InvoicePaybackTransfer>>;
  paymentDueTs?: Maybe<Scalars['DateTime']>;
  paymentIntents?: Maybe<Array<PaymentIntent>>;
  paymentMilestone?: Maybe<InvoicePaymentMilestone>;
  paymentType?: Maybe<Scalars['String']>;
  proInvoiceReference?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  refundDate?: Maybe<Scalars['DateTime']>;
  stripeFee?: Maybe<Scalars['Float']>;
  stripeStatus?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Int'];
  updatedBy?: Maybe<Scalars['String']>;
  updatedTs: Scalars['DateTime'];
  userProfiles?: Maybe<Array<UserProfile>>;
  voidedBy?: Maybe<Scalars['String']>;
  voidedTs?: Maybe<Scalars['DateTime']>;
  yzFeeRate?: Maybe<Scalars['String']>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  createdTs: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  invoiceId: Scalars['String'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  unitAmount: Scalars['Int'];
  updatedTs: Scalars['DateTime'];
};

export type InvoicePaybackTransfer = {
  __typename?: 'InvoicePaybackTransfer';
  amount: Scalars['Float'];
  createdTs: Scalars['DateTime'];
  invoiceId: Scalars['String'];
  paid: Scalars['Boolean'];
  stripeChargeId: Scalars['String'];
  stripePaymentId: Scalars['String'];
  stripeTransferId: Scalars['String'];
};

export enum InvoicePaymentMilestone {
  AdditionalProgressPayment = 'ADDITIONAL_PROGRESS_PAYMENT',
  ChangeOrder = 'CHANGE_ORDER',
  Deposit = 'DEPOSIT',
  FinalPayment = 'FINAL_PAYMENT',
  FirstProgressPayment = 'FIRST_PROGRESS_PAYMENT',
  ProjectDeposit = 'PROJECT_DEPOSIT'
}

export type IsUnreadForCurrentParticipantInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type LatestRevision = {
  __typename?: 'LatestRevision';
  revisionId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type LikeGalleryImageInput = {
  galleryImageId: Scalars['String'];
  like: Scalars['Boolean'];
  styleProfileId: Scalars['String'];
};

export type LoginHistory = {
  __typename?: 'LoginHistory';
  contractorId?: Maybe<Scalars['String']>;
  createdTs: Scalars['String'];
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type LoginHistoryPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<LoginHistorySortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum LoginHistorySortByEnum {
  CreatedTs = 'CREATED_TS'
}

export type Market = {
  __typename?: 'Market';
  avgContractAmount18moUsd: Scalars['Float'];
  contractSigned18moQty: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  displayName: Scalars['String'];
  engaged18moQty: Scalars['Float'];
  id: Scalars['String'];
  internalName: Scalars['String'];
  introductionMade18moQty: Scalars['Float'];
  marketEngaged18moCloseRate: Scalars['Float'];
  marketIntroduction18moCloseRate: Scalars['Float'];
  project18moQty: Scalars['Float'];
  tier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zipCodes?: Maybe<Array<Scalars['String']>>;
};

export enum MarketOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum MarketOrderBy {
  CreatedAt = 'CREATED_AT',
  DisplayName = 'DISPLAY_NAME',
  InternalName = 'INTERNAL_NAME',
  UpdatedAt = 'UPDATED_AT'
}

export type Match = {
  __typename?: 'Match';
  buildProjectId: Scalars['String'];
  contractor: Contractor;
  contractorId: Scalars['String'];
  contractorProfile: ContractorProfile;
  createdTs: Scalars['DateTime'];
  pipefyDtbCardId?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
  totalContractValue?: Maybe<Scalars['Int']>;
  updatedTs: Scalars['DateTime'];
  userProfile?: Maybe<UserProfileEnhanced>;
};

export type Media = {
  __typename?: 'Media';
  bucketName: Scalars['String'];
  buildProjectId?: Maybe<Scalars['String']>;
  contractorId?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  messageId?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  objectName: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  revisionId: Scalars['String'];
  source?: Maybe<MediaSource>;
  tags?: Maybe<Array<MediaTag>>;
  uploadConfirmed: Scalars['Boolean'];
  uploaderId?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type MediaMessage = {
  __typename?: 'MediaMessage';
  id: Scalars['String'];
  mimeType: Scalars['String'];
};

export type MediaPagination = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<MediaSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type MediaPangaea = {
  __typename?: 'MediaPangaea';
  downloadUrl: Scalars['String'];
  fileType: Scalars['String'];
  id: Scalars['String'];
  isClientVisible?: Maybe<Scalars['Boolean']>;
  isDesignerVisible?: Maybe<Scalars['Boolean']>;
  originalFileName?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  revisionId?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  uploadedAt: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export enum MediaSortByEnum {
  CreatedAt = 'CREATED_AT',
  Ordering = 'ORDERING'
}

export enum MediaSource {
  Messages = 'MESSAGES',
  NonYz = 'NON_YZ',
  OnboardingInspiration = 'ONBOARDING_INSPIRATION',
  OnboardingPhotos = 'ONBOARDING_PHOTOS',
  Unknown = 'UNKNOWN',
  Yz = 'YZ'
}

export enum MediaTag {
  Headshot = 'HEADSHOT',
  Thumbnail = 'THUMBNAIL'
}

export type Message = {
  __typename?: 'Message';
  author?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  conversationId: Scalars['String'];
  createdTs: Scalars['DateTime'];
  fromAccountManager?: Maybe<Scalars['String']>;
  fromAccountManagerName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnreadForCurrentParticipant: Scalars['Boolean'];
  media: Array<MediaMessage>;
  updatedTs?: Maybe<Scalars['DateTime']>;
};


export type MessageIsUnreadForCurrentParticipantArgs = {
  input: IsUnreadForCurrentParticipantInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  addContractorDocument?: Maybe<Scalars['String']>;
  addContractorMedia: Scalars['String'];
  applyMatchingAlgorithm: Array<Match>;
  assignAccountManager: BuildProject;
  clearContractorReviews: Scalars['Float'];
  createAccount: Account;
  createAccountManager: AccountManager;
  createActionAcknowledgement: ActionAcknowledgement;
  createAddress: Address;
  createApiKey: Scalars['String'];
  createBuildProject: BuildProject;
  createBuildRegion: BuildRegion;
  createBuildStudioUser?: Maybe<Scalars['String']>;
  createBuildStudioUserFromFirestoreData?: Maybe<Scalars['String']>;
  createContractor: Contractor;
  createContractorProfile: ContractorProfile;
  createContractorReview: ContractorReview;
  createConversation: Conversation;
  createCurrentUserTermsOfService: UserTermsOfService;
  createDesignOnboarding: DesignOnboarding;
  createEstimate: Estimate;
  createEstimatePaymentLineItem: EstimatePaymentLineItem;
  createEstimateWorkLineItem: EstimateWorkLineItem;
  createInvoice: Invoice;
  createLoginHistory: LoginHistory;
  createMatch: Match;
  createPhaseFormLink: Scalars['String'];
  createPipefyCards?: Maybe<UpdatePipefyCardResponse>;
  createPipefyWebhooks: Scalars['String'];
  createRevision: Revision;
  createStyleProfile: StyleProfile;
  createStyleProfileLikeGalleryImage: Array<GalleryImage>;
  createSurvey: Survey;
  createSurveyQuestion: Survey;
  createSurveyResponse: SurveyResponse;
  createTag: Scalars['String'];
  createUser: User;
  createUserProfile: UserProfile;
  createUserTermsOfService: UserTermsOfService;
  deleteContractorReview: ContractorReview;
  deleteEstimateBidFile: Estimate;
  designOnboardMessageSubmission: Message;
  ensureAccountSetup: EnsureAccountSetupResponse;
  getPresignedUrl: PresignedUrlResponse;
  getShareLink: GetShareLinkId;
  likeOrUnlikeImage: StatusObject;
  removeAccountManager: AccountManager;
  removeBuildProject: BuildProject;
  removeBuildRegion: StatusObject;
  removeContractor: Contractor;
  removeContractorProfile: ContractorProfile;
  removeDesignOnboarding: DesignOnboarding;
  removeEstimate: Estimate;
  removeEstimatePaymentLineItem: EstimatePaymentLineItem;
  removeEstimateWorkLineItem: EstimateWorkLineItem;
  removeMedia: Scalars['String'];
  removeRevision: Revision;
  removeTag: Scalars['String'];
  removeUserTermsOfService: UserTermsOfService;
  sendFeedbackSubmittedNotificationToSlack?: Maybe<Scalars['Boolean']>;
  sendGhostMessageFormSubmission: Message;
  sendPasswordResetEmail: Scalars['String'];
  setContractorProfileHeadshot: Scalars['String'];
  setContractorProfileThumbnail: Scalars['String'];
  setContractorProfileVideo: Scalars['String'];
  setContractorUserClaims: Scalars['String'];
  signUpAndSendMessageSubmission: Message;
  smsEvent: Scalars['String'];
  startInitialYardzenConversation?: Maybe<Conversation>;
  startLeadConvertedConversations?: Maybe<Array<Maybe<Conversation>>>;
  startPayment: PaymentRequest;
  submitEstimate: Estimate;
  submitRenderFeedback: RenderFeedback;
  submitRenderFeedbackSet: Array<RenderFeedback>;
  syncUserToCordial: SyncUserToCordialResponse;
  triggerScheduledMessages: Scalars['String'];
  updateAccount: Account;
  updateAccountManager: AccountManager;
  updateAddress: Address;
  updateBuildProject: BuildProject;
  updateBuildRegion: StatusObject;
  updateContractor: Contractor;
  updateContractorDocument: Scalars['String'];
  updateContractorProfile: ContractorProfile;
  updateContractorReview: ContractorReview;
  updateConversation: Conversation;
  updateCustomClaims: Scalars['String'];
  updateDesignOnboarding: DesignOnboarding;
  updateEstimate: Estimate;
  updateEstimatePaymentLineItem: EstimatePaymentLineItem;
  updateEstimateWorkLineItem: EstimateWorkLineItem;
  updateGalleryImage: GalleryImage;
  updateGhostedConversation: GhostedConversation;
  updateInvoice?: Maybe<Invoice>;
  updateMediaOrdering: Scalars['String'];
  updatePipefyProjectCardOnPhaseFormSubmitted: UpdatePipefyCardResponse;
  updateProfile: StatusObject;
  updateRevision: Revision;
  updateStyleProfile: StyleProfile;
  updateTotalContractValue: StatusObject;
  updateUser: User;
  updateUserEmail: Scalars['String'];
  updateUserProfile: UserProfile;
  updateUserProjectElementsBatch: StatusObject;
  updateUserTermsOfService: UserTermsOfService;
  uploadMediaMessage: WriteableMedia;
  upsertSurveyQuestionResponses: Array<SurveyQuestionResponse>;
};


export type MutationAddContractorDocumentArgs = {
  input: CreateContractorDocumentInput;
};


export type MutationAddContractorMediaArgs = {
  input: CreateContractorMediaInput;
};


export type MutationApplyMatchingAlgorithmArgs = {
  input: FindMatchesInput;
};


export type MutationAssignAccountManagerArgs = {
  input: AssignAccountManagerInput;
};


export type MutationClearContractorReviewsArgs = {
  contractorId: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateAccountManagerArgs = {
  input: CreateAccountManagerInput;
};


export type MutationCreateActionAcknowledgementArgs = {
  input: CreateActionAcknowledgementInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateBuildProjectArgs = {
  input: CreateBuildProjectInput;
};


export type MutationCreateBuildRegionArgs = {
  createBuildRegionInput: CreateBuildRegionInput;
};


export type MutationCreateBuildStudioUserArgs = {
  input: CreateBuildStudioUserInput;
};


export type MutationCreateBuildStudioUserFromFirestoreDataArgs = {
  userId: Scalars['String'];
};


export type MutationCreateContractorArgs = {
  createProfile?: InputMaybe<Scalars['Boolean']>;
  input: CreateContractorInput;
};


export type MutationCreateContractorProfileArgs = {
  input: CreateContractorProfileInput;
  syncLegacyDb?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateContractorReviewArgs = {
  input: CreateContractorReviewInput;
};


export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};


export type MutationCreateCurrentUserTermsOfServiceArgs = {
  input: CreateCurrentUserTermsOfServiceInput;
};


export type MutationCreateDesignOnboardingArgs = {
  input: CreateDesignOnboardingInput;
};


export type MutationCreateEstimateArgs = {
  input: CreateEstimateInput;
};


export type MutationCreateEstimatePaymentLineItemArgs = {
  input: CreateEstimatePaymentLineItemInput;
};


export type MutationCreateEstimateWorkLineItemArgs = {
  input: CreateEstimateWorkLineItemInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateLoginHistoryArgs = {
  input: CreateLoginHistoryInput;
};


export type MutationCreateMatchArgs = {
  input: CreateMatchInput;
};


export type MutationCreatePhaseFormLinkArgs = {
  input: PipefyPhaseFormLinkInput;
};


export type MutationCreatePipefyCardsArgs = {
  input: CreatePipefyCardInput;
};


export type MutationCreateRevisionArgs = {
  createRevisionInput: CreateRevisionInput;
};


export type MutationCreateStyleProfileArgs = {
  input: CreateStyleProfileInput;
};


export type MutationCreateStyleProfileLikeGalleryImageArgs = {
  input: CreateStyleProfileLikeImageInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationCreateSurveyQuestionArgs = {
  input: CreateSurveyQuestionInput;
};


export type MutationCreateSurveyResponseArgs = {
  input: CreateSurveyResponseInput;
};


export type MutationCreateTagArgs = {
  name: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserProfileArgs = {
  input: CreateUserProfileInput;
};


export type MutationCreateUserTermsOfServiceArgs = {
  input: CreateUserTermsOfServiceInput;
};


export type MutationDeleteContractorReviewArgs = {
  id: Scalars['String'];
};


export type MutationDeleteEstimateBidFileArgs = {
  estimateId: Scalars['String'];
  mediaId: Scalars['String'];
};


export type MutationDesignOnboardMessageSubmissionArgs = {
  input: DesignOnboardMessageSubmissionInput;
};


export type MutationEnsureAccountSetupArgs = {
  input: EnsureAccountSetupInput;
};


export type MutationGetPresignedUrlArgs = {
  input: CreatePipefyAttachmentUrlInput;
};


export type MutationGetShareLinkArgs = {
  input: GetProjectBriefMediaInput;
};


export type MutationLikeOrUnlikeImageArgs = {
  input: LikeGalleryImageInput;
};


export type MutationRemoveAccountManagerArgs = {
  id: Scalars['String'];
};


export type MutationRemoveBuildProjectArgs = {
  id: Scalars['String'];
};


export type MutationRemoveBuildRegionArgs = {
  id: Scalars['String'];
};


export type MutationRemoveContractorArgs = {
  input: DeleteContractorInput;
};


export type MutationRemoveContractorProfileArgs = {
  id: Scalars['String'];
};


export type MutationRemoveDesignOnboardingArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEstimateArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEstimatePaymentLineItemArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEstimateWorkLineItemArgs = {
  id: Scalars['String'];
};


export type MutationRemoveMediaArgs = {
  id: Scalars['String'];
};


export type MutationRemoveRevisionArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTagArgs = {
  name: Scalars['String'];
};


export type MutationRemoveUserTermsOfServiceArgs = {
  id: Scalars['String'];
};


export type MutationSendFeedbackSubmittedNotificationToSlackArgs = {
  input: SendFeedbackSubmittedNotificationToSlackInput;
};


export type MutationSendGhostMessageFormSubmissionArgs = {
  input: SendGhostMessageFormSubmissionInput;
};


export type MutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};


export type MutationSetContractorProfileHeadshotArgs = {
  contractorId: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationSetContractorProfileThumbnailArgs = {
  contractorId: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationSetContractorProfileVideoArgs = {
  contractorId: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationSetContractorUserClaimsArgs = {
  input: SetContractorUserClaimsInput;
};


export type MutationSignUpAndSendMessageSubmissionArgs = {
  input: SignUpAndSendMessageSubmissionInput;
};


export type MutationSmsEventArgs = {
  event: SmsEventInput;
};


export type MutationStartInitialYardzenConversationArgs = {
  input: CreateConversationInput;
};


export type MutationStartLeadConvertedConversationsArgs = {
  input: StartLeadConversationsInput;
};


export type MutationStartPaymentArgs = {
  input: InitPaymentDto;
};


export type MutationSubmitEstimateArgs = {
  id: Scalars['String'];
};


export type MutationSubmitRenderFeedbackArgs = {
  input: RenderFeedbackInput;
};


export type MutationSubmitRenderFeedbackSetArgs = {
  input: RenderFeedbackSetInput;
};


export type MutationSyncUserToCordialArgs = {
  input: SyncUserToCordialInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAccountManagerArgs = {
  input: UpdateAccountManagerInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateBuildProjectArgs = {
  input: UpdateBuildProjectInput;
};


export type MutationUpdateBuildRegionArgs = {
  updateBuildRegionInput: UpdateBuildRegionInput;
};


export type MutationUpdateContractorArgs = {
  input: UpdateContractorInput;
};


export type MutationUpdateContractorDocumentArgs = {
  input: UpdateContractorDocumentInput;
};


export type MutationUpdateContractorProfileArgs = {
  input: UpdateContractorProfileInput;
  syncLegacyDb?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateContractorReviewArgs = {
  input: UpdateContractorReviewInput;
};


export type MutationUpdateConversationArgs = {
  input: UpdateConversationInput;
};


export type MutationUpdateCustomClaimsArgs = {
  userId: Scalars['String'];
};


export type MutationUpdateDesignOnboardingArgs = {
  input: UpdateDesignOnboardingInput;
};


export type MutationUpdateEstimateArgs = {
  input: UpdateEstimateInput;
};


export type MutationUpdateEstimatePaymentLineItemArgs = {
  input: UpdateEstimatePaymentLineItemInput;
};


export type MutationUpdateEstimateWorkLineItemArgs = {
  input: UpdateEstimateWorkLineItemInput;
};


export type MutationUpdateGalleryImageArgs = {
  input: UpdateGalleryImageInput;
};


export type MutationUpdateGhostedConversationArgs = {
  input: UpdateGhostedConversationInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateMediaOrderingArgs = {
  input: UpdateContractorMediaOrderInput;
};


export type MutationUpdatePipefyProjectCardOnPhaseFormSubmittedArgs = {
  input: PipefyCardFieldsUpdateInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateRevisionArgs = {
  updateRevisionInput: UpdateRevisionInput;
};


export type MutationUpdateStyleProfileArgs = {
  input: UpdateStyleProfileInput;
};


export type MutationUpdateTotalContractValueArgs = {
  input: UpdateTotalContractValueInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateUserProjectElementsBatchArgs = {
  input: UpdateUserProjectElementsBatchInput;
};


export type MutationUpdateUserTermsOfServiceArgs = {
  input: UpdateUserTermsOfServiceInput;
};


export type MutationUploadMediaMessageArgs = {
  input: UploadMediaMessageInput;
};


export type MutationUpsertSurveyQuestionResponsesArgs = {
  input: UpsertSurveyQuestionResponsesInput;
};

export type NestedCreateInvoiceLineItemInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  unitAmount: Scalars['Int'];
};

export enum OrderBy {
  BusinessName = 'BUSINESS_NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type ParticipantInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  role: ConversationRole;
  twilioId?: InputMaybe<Scalars['String']>;
  userProfileId?: InputMaybe<Scalars['String']>;
};

export enum PausedStatusReason {
  ArViolation = 'AR_VIOLATION',
  ContractorRequested = 'CONTRACTOR_REQUESTED',
  MissingLicense = 'MISSING_LICENSE',
  NegativeReviews = 'NEGATIVE_REVIEWS',
  None = 'NONE',
  NoOnlinePresence = 'NO_ONLINE_PRESENCE',
  PerformanceQualityIssues = 'PERFORMANCE_QUALITY_ISSUES',
  ProDesignOnly = 'PRO_DESIGN_ONLY',
  Requested = 'REQUESTED',
  UnresponsiveNoUpdates = 'UNRESPONSIVE_NO_UPDATES',
  UnresponsiveYardzen = 'UNRESPONSIVE_YARDZEN',
  VettingIssue = 'VETTING_ISSUE'
}

export type Payment = {
  __typename?: 'Payment';
  clientSecret: Scalars['String'];
  connectedAccountId?: Maybe<Scalars['String']>;
  hasFailed?: Maybe<Scalars['Boolean']>;
  paymentIntentId: Scalars['String'];
  paymentRequestId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  amount: Scalars['Float'];
  amountCapturable: Scalars['Float'];
  amountReceived: Scalars['Float'];
  application?: Maybe<Scalars['String']>;
  applicationFeeAmount?: Maybe<Scalars['Float']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationReason?: Maybe<Scalars['String']>;
  captureMethod: Scalars['String'];
  clientSecret: Scalars['String'];
  confirmationMethod: Scalars['String'];
  created: Scalars['Float'];
  currency: Scalars['String'];
  customer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceId?: Maybe<Scalars['String']>;
  latestCharge?: Maybe<Scalars['String']>;
  livemode: Scalars['Boolean'];
  onBehalfOf?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodTypes: Array<Scalars['String']>;
  receiptEmail?: Maybe<Scalars['String']>;
  review?: Maybe<Scalars['String']>;
  setupFutureUsage?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  statementDescriptorSuffix?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  stripeCharge?: Maybe<StripeCharge>;
  transferGroup?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card?: Maybe<Card>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentMethodDetails = {
  __typename?: 'PaymentMethodDetails';
  card?: Maybe<Card>;
};

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  paid: Scalars['Boolean'];
  payment?: Maybe<Payment>;
};

export type PhaseField = {
  __typename?: 'PhaseField';
  custom_validation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  internal_id?: Maybe<Scalars['String']>;
  is_multiple?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PhaseFieldCondition = {
  __typename?: 'PhaseFieldCondition';
  actions?: Maybe<Array<FieldConditionAction>>;
  condition?: Maybe<FieldCondition>;
  name: Scalars['String'];
  phase?: Maybe<CardPhase>;
};

export type Pipe = {
  __typename?: 'Pipe';
  fieldConditions?: Maybe<Array<PhaseFieldCondition>>;
  id: Scalars['String'];
  phases?: Maybe<Array<CardPhase>>;
};

export type PipefyCardFieldsUpdateInput = {
  cardId: Scalars['Int'];
  fields: Array<PipefyFieldValueInput>;
};

export type PipefyField = {
  __typename?: 'PipefyField';
  id: Scalars['String'];
};

export type PipefyFieldValueInput = {
  fieldId: Scalars['String'];
  fieldValue: Array<Scalars['String']>;
};

export type PipefyPhaseFormLinkInput = {
  cardId: Scalars['Float'];
};

export type PresignedUrlResponse = {
  __typename?: 'PresignedUrlResponse';
  url: Scalars['String'];
};

export type ProDesignCard = {
  __typename?: 'ProDesignCard';
  buildProject?: Maybe<BuildProject>;
  cardCreatedDataTeam?: Maybe<Scalars['DateTime']>;
  ccAuthPending?: Maybe<Array<Scalars['String']>>;
  ccAuthorizationCompleted?: Maybe<Array<Scalars['String']>>;
  clientCard?: Maybe<ClientCard>;
  clientNameStreet?: Maybe<Scalars['String']>;
  contractor?: Maybe<Array<Scalars['String']>>;
  contractorCompany?: Maybe<Scalars['String']>;
  contractorEmail?: Maybe<Scalars['String']>;
  contractorPhoneNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPhase?: Maybe<CardPhase>;
  dealValue?: Maybe<Scalars['Float']>;
  done?: Maybe<Scalars['Boolean']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  fields?: Maybe<Array<CardField>>;
  finalContractAmount?: Maybe<Scalars['Float']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  invoiceNumber?: Maybe<Scalars['String']>;
  liisaLink?: Maybe<Scalars['String']>;
  liisaLoginEmail?: Maybe<Scalars['String']>;
  linkToShareMedia?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  proVettedAndApproved?: Maybe<Array<Scalars['String']>>;
  projectOutcome?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  yzBuild?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  createdAt: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  shareableLink?: Maybe<ShareableLink>;
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
};

export type ProjectCard = {
  __typename?: 'ProjectCard';
  buildProject?: Maybe<BuildProject>;
  clientCard?: Maybe<ClientCard>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  currentPhase?: Maybe<CardPhase>;
  done?: Maybe<Scalars['Boolean']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  emailMessagingAddress?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<CardField>>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  fullAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  linkToShareMedia?: Maybe<Scalars['String']>;
  matchingType?: Maybe<Scalars['String']>;
  pipe: Pipe;
  surveyResponses?: Maybe<Array<SurveyResponse>>;
  title: Scalars['String'];
  updatePhaseLink?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['String']>;
};

export enum ProjectDigestFrequency {
  Daily = 'DAILY',
  None = 'NONE',
  Weekly = 'WEEKLY'
}

export type ProjectElement = {
  __typename?: 'ProjectElement';
  budgetLowerBound?: Maybe<Scalars['Int']>;
  budgetUpperBound?: Maybe<Scalars['Int']>;
  category: Scalars['String'];
  id: Scalars['String'];
  isSelected?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  rankingInCategory: Scalars['Float'];
};


export type ProjectElementIsSelectedArgs = {
  styleProfileId: Scalars['String'];
};

export type ProjectElementsBatchInput = {
  projectElementId: Scalars['String'];
  select: Scalars['Boolean'];
};

export type PublicAccount = {
  __typename?: 'PublicAccount';
  id: Scalars['String'];
  users?: Maybe<Array<PublicUser>>;
};

export type PublicBuildProject = {
  __typename?: 'PublicBuildProject';
  account?: Maybe<PublicAccount>;
  id: Scalars['String'];
};

export type PublicContractor = {
  __typename?: 'PublicContractor';
  businessName: Scalars['String'];
  city: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type PublicEstimate = {
  __typename?: 'PublicEstimate';
  buildProject?: Maybe<PublicBuildProject>;
  contractor?: Maybe<PublicContractor>;
  estimateBidFiles?: Maybe<Array<PublicMedia>>;
  id: Scalars['String'];
};

export type PublicMedia = {
  __typename?: 'PublicMedia';
  id: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};

export type PublicUser = {
  __typename?: 'PublicUser';
  id: Scalars['String'];
  userProfile?: Maybe<PublicUserProfile>;
};

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<Address>;
};

export type Query = {
  __typename?: 'Query';
  acceptedCurrentTermsOfService: Array<UserTermsOfService>;
  account: Account;
  accountManager: AccountManager;
  accountManagers: Array<AccountManager>;
  actionAcknowledgement: ActionAcknowledgement;
  address?: Maybe<Address>;
  addresses?: Maybe<Array<Address>>;
  adminConversations: Array<Conversation>;
  allMatches: Array<Match>;
  availableWorkLineItemTags: Array<Scalars['String']>;
  buildProject: BuildProject;
  buildProjects: Array<BuildProject>;
  buildRegion: BuildRegion;
  buildRegions: Array<BuildRegion>;
  checkInvoicePayable: StatusObject;
  checkPaymentIntentForInvoice: StatusObject;
  cityProjectElements: Array<CityProjectElement>;
  contractor?: Maybe<Contractor>;
  contractorInvoice: Invoice;
  contractorProfile?: Maybe<ContractorProfile>;
  contractorReviews: Array<ContractorReview>;
  contractors: Array<Contractor>;
  conversation: Conversation;
  conversationParticipants: Array<ConversationParticipant>;
  conversations: Array<Conversation>;
  designOnboarding?: Maybe<DesignOnboarding>;
  estimate: Estimate;
  estimatePaymentLineItems: Array<EstimatePaymentLineItem>;
  estimateWorkLineItem: EstimateWorkLineItem;
  estimateWorkLineItems: Array<EstimateWorkLineItem>;
  estimates?: Maybe<Array<Estimate>>;
  fetchAllContractorIds: Array<ContractorProfile>;
  findClassicBuildProjectsForContractor: Array<ProjectCard>;
  findMarketplaceBuildProjectsForContractor: Array<ProjectCard>;
  findProDesignCards: Array<ProDesignCard>;
  findTermsOfService: Array<UserTermsOfService>;
  findUser: User;
  galleryImage?: Maybe<GalleryImage>;
  galleryImages: Array<GalleryImage>;
  getLikedRenders: Array<RenderFeedback>;
  getRenderFeedback: Array<RenderFeedback>;
  ghostedConversations: Array<GhostedConversation>;
  invoice: Invoice;
  invoices: Array<Invoice>;
  latestBuildProject?: Maybe<BuildProject>;
  latestConversationMessage?: Maybe<Array<Message>>;
  latestDeliveredRevision?: Maybe<LatestRevision>;
  likedGalleryImages: Array<GalleryImage>;
  loginHistory: Array<LoginHistory>;
  markets: Array<Market>;
  matches: Array<Match>;
  messages: Array<Message>;
  payableContractorProfiles: Array<ContractorProfile>;
  profile?: Maybe<Profile>;
  projectBriefElementsExportData: BriefElementsExportData;
  projectBriefHeaderInformation: BriefHeaderInformation;
  projectBriefMaterialExportData: BriefMaterialExportData;
  projectBriefMedia: Array<BriefMedia>;
  projectBriefPlantExportData: BriefPlantExportData;
  projectElements: Array<ProjectElement>;
  publicEstimate: PublicEstimate;
  publicProjectElements: Array<ProjectElement>;
  readMediaMessage: Media;
  renderFeedbacks: Array<RenderFeedback>;
  revision: Revision;
  revisions: Array<Revision>;
  searchContractorProfiles: Array<ContractorProfile>;
  searchInvoices: Array<Invoice>;
  searchUserProfiles?: Maybe<Array<UserProfile>>;
  searchUsers: UserSearchResponse;
  shareableLink: ShareableLink;
  styleProfile: StyleProfile;
  styleProfiles: Array<StyleProfile>;
  survey: Survey;
  surveyResponse: Array<SurveyResponse>;
  unreadMessagesCountByConversationParticipant: Scalars['Float'];
  url?: Maybe<Url>;
  userProfile?: Maybe<UserProfile>;
  userTermsOfService: UserTermsOfService;
  users?: Maybe<Array<User>>;
};


export type QueryAcceptedCurrentTermsOfServiceArgs = {
  input: FindAllUserTermsOfServiceInput;
};


export type QueryAccountArgs = {
  input: FindOneAccountInput;
};


export type QueryAccountManagerArgs = {
  id: Scalars['String'];
};


export type QueryActionAcknowledgementArgs = {
  key: Scalars['String'];
};


export type QueryAddressArgs = {
  id: Scalars['String'];
};


export type QueryAddressesArgs = {
  input: FindAllAddressesInput;
};


export type QueryAdminConversationsArgs = {
  input: FetchAdminConversationsInput;
};


export type QueryAllMatchesArgs = {
  input: FindAllMatchesInput;
};


export type QueryBuildProjectArgs = {
  input: FindBuildProjectInput;
};


export type QueryBuildProjectsArgs = {
  input: FindAllBuildProjectsInput;
};


export type QueryBuildRegionArgs = {
  input: FindOneBuildRegionInput;
};


export type QueryBuildRegionsArgs = {
  input: FindAllBuildRegionInput;
};


export type QueryCheckInvoicePayableArgs = {
  invoiceId: Scalars['String'];
};


export type QueryCheckPaymentIntentForInvoiceArgs = {
  invoiceId: Scalars['String'];
  paymentIntentId: Scalars['String'];
};


export type QueryCityProjectElementsArgs = {
  input: FindAllProjectElementsByCityInput;
};


export type QueryContractorArgs = {
  input: FindOneContractorInput;
};


export type QueryContractorInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryContractorProfileArgs = {
  contractorId: Scalars['String'];
};


export type QueryContractorReviewsArgs = {
  input: FindAllContractorReviewsInput;
};


export type QueryContractorsArgs = {
  input: FindAllContractorsInput;
};


export type QueryConversationArgs = {
  input: FindOneConversationInput;
};


export type QueryConversationParticipantsArgs = {
  input: FindAllConversationParticipantsInput;
};


export type QueryConversationsArgs = {
  input: FindAllConversationsInput;
};


export type QueryDesignOnboardingArgs = {
  input: FindOneDesignOnboardingInput;
};


export type QueryEstimateArgs = {
  id: Scalars['String'];
};


export type QueryEstimatePaymentLineItemsArgs = {
  input: FindAllEstimatePaymentLineItemsInput;
};


export type QueryEstimateWorkLineItemArgs = {
  id: Scalars['String'];
};


export type QueryEstimateWorkLineItemsArgs = {
  input: FindAllEstimateWorkLineItemsInput;
};


export type QueryEstimatesArgs = {
  input: FindAllEstimatesInput;
};


export type QueryFindProDesignCardsArgs = {
  contractorId: Scalars['String'];
};


export type QueryFindTermsOfServiceArgs = {
  input: FindAllUserTermsOfServiceInput;
};


export type QueryFindUserArgs = {
  id: Scalars['String'];
};


export type QueryGalleryImageArgs = {
  input: FindOneGalleryImageInput;
};


export type QueryGalleryImagesArgs = {
  input: FindAllGalleryImageInput;
};


export type QueryGetLikedRendersArgs = {
  segmentId: Scalars['String'];
};


export type QueryGetRenderFeedbackArgs = {
  segmentId: Scalars['String'];
};


export type QueryGhostedConversationsArgs = {
  input: GetGhostedConversationsInput;
};


export type QueryInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryInvoicesArgs = {
  input: FindInvoicesInput;
};


export type QueryLatestBuildProjectArgs = {
  accountId: Scalars['String'];
};


export type QueryLatestConversationMessageArgs = {
  input: GetLatestConversationMessageInput;
};


export type QueryLatestDeliveredRevisionArgs = {
  shareLinkId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type QueryLikedGalleryImagesArgs = {
  input: FindAllGalleryImageInput;
};


export type QueryLoginHistoryArgs = {
  input: FindAllLoginHistoryInput;
};


export type QueryMarketsArgs = {
  input: FindAllMarketsInput;
};


export type QueryMatchesArgs = {
  input: FindMatchesInput;
};


export type QueryMessagesArgs = {
  input: FindAllMessagesInput;
};


export type QueryProfileArgs = {
  id: Scalars['String'];
};


export type QueryProjectBriefElementsExportDataArgs = {
  input: GetProjectBriefMediaInput;
};


export type QueryProjectBriefHeaderInformationArgs = {
  input: GetProjectBriefMediaInput;
};


export type QueryProjectBriefMaterialExportDataArgs = {
  input: GetProjectBriefMediaInput;
};


export type QueryProjectBriefMediaArgs = {
  input: GetProjectBriefMediaInput;
};


export type QueryProjectBriefPlantExportDataArgs = {
  input: GetProjectBriefMediaInput;
};


export type QueryProjectElementsArgs = {
  input: FindProjectElementsInput;
};


export type QueryPublicEstimateArgs = {
  id: Scalars['String'];
};


export type QueryReadMediaMessageArgs = {
  input: ReadMediaMessageInput;
};


export type QueryRenderFeedbacksArgs = {
  input: GetNextRenderFeedbackSetInput;
};


export type QueryRevisionArgs = {
  id: Scalars['String'];
};


export type QueryRevisionsArgs = {
  input: FindAllRevisionsInput;
};


export type QuerySearchContractorProfilesArgs = {
  input: SearchContractorProfilesInput;
};


export type QuerySearchInvoicesArgs = {
  input: SearchInvoicesInput;
};


export type QuerySearchUserProfilesArgs = {
  input: SearchUserProfilesInput;
};


export type QuerySearchUsersArgs = {
  input: SearchUsersInput;
};


export type QueryShareableLinkArgs = {
  id: Scalars['String'];
};


export type QueryStyleProfileArgs = {
  input: FindByStyleProfileInput;
};


export type QueryStyleProfilesArgs = {
  input: FindAllStyleProfileInput;
};


export type QuerySurveyArgs = {
  input: FindSurveyInput;
};


export type QuerySurveyResponseArgs = {
  input: FindSurveyResponseInput;
};


export type QueryUnreadMessagesCountByConversationParticipantArgs = {
  input: GetUnreadMessagesInput;
};


export type QueryUrlArgs = {
  id: Scalars['String'];
};


export type QueryUserProfileArgs = {
  input: FindOneUserProfileInput;
};


export type QueryUserTermsOfServiceArgs = {
  input: FindOneUserTermsOfServiceInput;
};


export type QueryUsersArgs = {
  input: FindAllUsersInput;
};

export type ReadMediaMessageInput = {
  id: Scalars['String'];
};

export enum Region {
  Midwest = 'MIDWEST',
  MidAtlantic = 'MID_ATLANTIC',
  Northeast = 'NORTHEAST',
  Pacific = 'PACIFIC',
  PacificNorthwest = 'PACIFIC_NORTHWEST',
  RockyMountain = 'ROCKY_MOUNTAIN',
  Southeast = 'SOUTHEAST',
  Southwest = 'SOUTHWEST'
}

export type RenderFeedback = {
  __typename?: 'RenderFeedback';
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  dislikedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  likedAt?: Maybe<Scalars['DateTime']>;
  likedFrom?: Maybe<Scalars['String']>;
  package?: Maybe<Scalars['String']>;
  segmentId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
};

export type RenderFeedbackInput = {
  comments?: InputMaybe<Scalars['String']>;
  dislikedAt?: InputMaybe<Scalars['DateTime']>;
  externalId: Scalars['Float'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isLiked?: InputMaybe<Scalars['Boolean']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
  likedAt?: InputMaybe<Scalars['DateTime']>;
  likedFrom?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<Scalars['String']>;
  segmentId?: InputMaybe<Scalars['String']>;
  style?: InputMaybe<Scalars['String']>;
};

export type RenderFeedbackSetInput = {
  feedbackSet: Array<RenderFeedbackInput>;
};

export type ResolveBuildProjectPipefyCardsInput = {
  contractorId: Scalars['String'];
};

export type ResolvedProjectCard = {
  __typename?: 'ResolvedProjectCard';
  buildProjectId: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  cardPhaseId?: Maybe<Scalars['String']>;
  cardPhaseName?: Maybe<Scalars['String']>;
  cardPipeId?: Maybe<Scalars['String']>;
  cardPipeName?: Maybe<Scalars['String']>;
  contractorId: Scalars['String'];
};

export type Revision = {
  __typename?: 'Revision';
  clientFirstName: Scalars['String'];
  clientLastName: Scalars['String'];
  contractorId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  media: Array<Media>;
  projectCity: Scalars['String'];
  projectPackage: Scalars['String'];
  projectState: Scalars['String'];
  shareMediaLink?: Maybe<Scalars['String']>;
};


export type RevisionMediaArgs = {
  pagination?: InputMaybe<MediaPagination>;
};

export type RevisionPagination = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<RevisionSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum RevisionSortByEnum {
  ContractorId = 'CONTRACTOR_ID'
}

export type SearchContractorProfilesInput = {
  approvalStatus?: InputMaybe<ApprovalStatus>;
  pausedReason?: InputMaybe<PausedStatusReason>;
  superPro?: InputMaybe<Scalars['Boolean']>;
  term: Scalars['String'];
};

export type SearchInvoicesInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchUserProfilesInput = {
  searchTerm: Scalars['String'];
};

export type SearchUsersInput = {
  page?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  term: Scalars['String'];
};

export type SendFeedbackSubmittedNotificationToSlackInput = {
  message: Scalars['String'];
  userId: Scalars['String'];
};

export type SendGhostMessageFormSubmissionInput = {
  conversationId: Scalars['String'];
  fromAccountManager?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
};

export type SendPasswordResetEmailInput = {
  baseUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type SetContractorUserClaimsInput = {
  contractorId: Scalars['String'];
  email: Scalars['String'];
};

export type ShareableLink = {
  __typename?: 'ShareableLink';
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isContractorOnly?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<MediaPangaea>>;
  updatedAt: Scalars['String'];
};

export type SignUpAndSendMessageSubmissionInput = {
  contractorId: Scalars['String'];
  message: Scalars['String'];
  userProfileId: Scalars['String'];
};

export type SmsEventInput = {
  sid: Scalars['String'];
  status: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartLeadConversationsInput = {
  userId: Scalars['String'];
};

export type StatusObject = {
  __typename?: 'StatusObject';
  ok: Scalars['Boolean'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StripeCharge = {
  __typename?: 'StripeCharge';
  amount: Scalars['Float'];
  amountCaptured: Scalars['Float'];
  amountRefunded: Scalars['Float'];
  application?: Maybe<Scalars['String']>;
  applicationFee?: Maybe<Scalars['String']>;
  applicationFeeAmount?: Maybe<Scalars['Float']>;
  balanceTransaction: Scalars['String'];
  calculatedStatementDescriptor: Scalars['String'];
  captured: Scalars['Boolean'];
  created: Scalars['Float'];
  currency: Scalars['String'];
  customer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disputed: Scalars['Boolean'];
  failureBalanceTransaction?: Maybe<Scalars['String']>;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoice?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  livemode: Scalars['Boolean'];
  object: Scalars['String'];
  onBehalfOf?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  paymentIntent?: Maybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  paymentMethodDetails?: Maybe<PaymentMethodDetails>;
  receiptEmail?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  receiptUrl: Scalars['String'];
  refunded: Scalars['Boolean'];
  review?: Maybe<Scalars['String']>;
  sourceTransfer?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  statementDescriptorSuffix?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tranferData?: Maybe<Scalars['String']>;
  transferGroup?: Maybe<Scalars['String']>;
};

export enum Style {
  Contemporary = 'CONTEMPORARY',
  Cottage = 'COTTAGE',
  ModernBoho = 'MODERN_BOHO',
  ModernFarmhouse = 'MODERN_FARMHOUSE',
  Moditerranean = 'MODITERRANEAN',
  Rustic = 'RUSTIC',
  Southwest = 'SOUTHWEST',
  Traditional = 'TRADITIONAL'
}

export type StyleProfile = {
  __typename?: 'StyleProfile';
  galleryImages?: Maybe<Array<GalleryImage>>;
  id: Scalars['String'];
  maxBudget?: Maybe<Scalars['Float']>;
  styleResults: Array<StyleResult>;
  timeline?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  yardLocation?: Maybe<YardLocation>;
};

export type StyleProfilePaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<StyleProfilesPaginatorSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum StyleProfilesPaginatorSortByEnum {
  CreatedTs = 'CREATED_TS'
}

export type StyleResult = {
  __typename?: 'StyleResult';
  imageUrl: Scalars['String'];
  percentage: Scalars['Float'];
  styleDescription: Scalars['String'];
  styleName: Scalars['String'];
};

export type Survey = {
  __typename?: 'Survey';
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  surveyDesc: Scalars['String'];
  surveyKey: Scalars['String'];
  surveyQuestions: Array<SurveyQuestion>;
  updatedTs?: Maybe<Scalars['DateTime']>;
  version: Scalars['Float'];
};


export type SurveySurveyQuestionsArgs = {
  input?: InputMaybe<SurveyQuestionsInput>;
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  createdTs: Scalars['DateTime'];
  format: Scalars['String'];
  id: Scalars['String'];
  index: Scalars['Float'];
  questionKey: Scalars['String'];
  required: Scalars['Boolean'];
  section: Scalars['Float'];
  surveyId: Scalars['String'];
  text: Scalars['String'];
  updatedTs?: Maybe<Scalars['DateTime']>;
};

export type SurveyQuestionResponse = {
  __typename?: 'SurveyQuestionResponse';
  createdTs: Scalars['DateTime'];
  response: Scalars['String'];
  surveyQuestion?: Maybe<SurveyQuestion>;
  surveyQuestionId: Scalars['String'];
  surveyResponseId: Scalars['String'];
  updatedTs?: Maybe<Scalars['DateTime']>;
};

export type SurveyQuestionsInput = {
  onlyActiveQuestions?: InputMaybe<Scalars['Boolean']>;
};

export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  buildProjectId: Scalars['String'];
  completed: Scalars['Boolean'];
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  surveyId: Scalars['String'];
  surveyQuestionReponses: Array<SurveyQuestionResponse>;
  updatedTs?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SyncUserToCordialInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type SyncUserToCordialResponse = {
  __typename?: 'SyncUserToCordialResponse';
  email: Scalars['String'];
};

export type UpdateAccountInput = {
  id: Scalars['String'];
};

export type UpdateAccountManagerInput = {
  buildRegionIds?: InputMaybe<Array<Scalars['String']>>;
  consultCallUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  projectManagerId?: InputMaybe<Scalars['String']>;
  slackChannelId?: InputMaybe<Scalars['String']>;
  thumbnailMediaId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateBuildProjectInput = {
  accountId?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Array<Scalars['Float']>>;
  id: Scalars['String'];
  internalNotes?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<Array<Scalars['String']>>;
  proFacingNotes?: InputMaybe<Scalars['String']>;
  projectElements?: InputMaybe<Array<ProjectElementsBatchInput>>;
};

export type UpdateBuildRegionInput = {
  accountManagerId?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  areaGeometry?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  internalName?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateContractorDocumentInput = {
  confirmationLink?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  kind: Scalars['String'];
  licenseNumber?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  policyNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateContractorInput = {
  abilities?: InputMaybe<Array<Scalars['String']>>;
  acceptingWork?: InputMaybe<Scalars['Boolean']>;
  approvalStatus?: InputMaybe<Scalars['String']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  businessName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  closeRate?: InputMaybe<Scalars['Float']>;
  coordinates?: InputMaybe<InputCoordinates>;
  currentBuildRegionId?: InputMaybe<Scalars['String']>;
  dashboardAccessEmails?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  maxSuccessBudget?: InputMaybe<Scalars['Float']>;
  minSuccessBudget?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  paused?: InputMaybe<Scalars['Boolean']>;
  pausedStatusReason?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pipefyContractorCardId?: InputMaybe<Scalars['Float']>;
  pointOfContactEmail?: InputMaybe<Scalars['String']>;
  pointOfContactName?: InputMaybe<Scalars['String']>;
  pointOfContactPhone?: InputMaybe<Scalars['String']>;
  preferred?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<UpdateContractorProfileInput>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpdateContractorMediaOrderInput = {
  mediaId: Scalars['String'];
  order: Scalars['Float'];
};

export type UpdateContractorProfileInput = {
  aboutSummary?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  attributes?: InputMaybe<Array<ContractorAttribute>>;
  budgetRangeLowerBound?: InputMaybe<Scalars['Int']>;
  budgetRangeUpperBound?: InputMaybe<Scalars['Int']>;
  businessName?: InputMaybe<Scalars['String']>;
  calendlyUrl?: InputMaybe<Scalars['String']>;
  contractorId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  googleUrl?: InputMaybe<Scalars['String']>;
  headshotMediaUrl?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  licenseExpirationDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  maxSuccessBudget?: InputMaybe<Scalars['Int']>;
  minSuccessBudget?: InputMaybe<Scalars['Int']>;
  projectDigestFrequency?: InputMaybe<ProjectDigestFrequency>;
  promotionalMessage?: InputMaybe<Scalars['String']>;
  promotionalMessageEndDate?: InputMaybe<Scalars['DateTime']>;
  promotionalMessageStartDate?: InputMaybe<Scalars['DateTime']>;
  rating?: InputMaybe<Scalars['Float']>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  thumbnailMediaUrl?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  whyYardzenLovesSummary?: InputMaybe<Scalars['String']>;
  yardAiEnabled?: InputMaybe<Scalars['Boolean']>;
  yearsInBusiness?: InputMaybe<Scalars['Int']>;
  yelpUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateContractorReviewInput = {
  author?: InputMaybe<Scalars['String']>;
  contractorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  reviewedTs?: InputMaybe<Scalars['DateTime']>;
  source?: InputMaybe<ContractorReviewSource>;
  summary?: InputMaybe<Scalars['String']>;
};

export type UpdateConversationInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  sid?: InputMaybe<Scalars['String']>;
};

export type UpdateDesignOnboardingInput = {
  additionalNotes?: InputMaybe<Scalars['String']>;
  buildProjectId?: InputMaybe<Scalars['String']>;
  dimensionsLength?: InputMaybe<Scalars['Float']>;
  dimensionsWidth?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  inspirationLinks?: InputMaybe<Array<Scalars['String']>>;
  totalSquareFootage?: InputMaybe<Scalars['Float']>;
};

export type UpdateEstimateInput = {
  buildProjectId?: InputMaybe<Scalars['String']>;
  clonedFromEstimateId?: InputMaybe<Scalars['String']>;
  contractorDeposit?: InputMaybe<Scalars['Int']>;
  contractorId?: InputMaybe<Scalars['String']>;
  evaluationNotes?: InputMaybe<Scalars['String']>;
  expectedEndDate?: InputMaybe<Scalars['DateTime']>;
  expectedStartDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  platformFee?: InputMaybe<Scalars['Int']>;
  proEstimateNumber?: InputMaybe<Scalars['String']>;
  proTermDetails?: InputMaybe<Scalars['String']>;
  sentToClient?: InputMaybe<Scalars['Boolean']>;
  sentToClientBy?: InputMaybe<Scalars['String']>;
  sentToClientTs?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type UpdateEstimatePaymentLineItemInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  paymentDue?: InputMaybe<Scalars['String']>;
  totalAmountDue?: InputMaybe<Scalars['Int']>;
};

export type UpdateEstimateWorkLineItemInput = {
  clientFeedbackNotes?: InputMaybe<Scalars['String']>;
  clientFeedbackTs?: InputMaybe<Scalars['DateTime']>;
  clonedFromEstimateWorkLineItemId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  totalCost?: InputMaybe<Scalars['Int']>;
};

export type UpdateGalleryImageInput = {
  amenities?: InputMaybe<Array<Scalars['String']>>;
  city: Scalars['String'];
  designPackage: Scalars['String'];
  id: Scalars['String'];
  ranking: Scalars['Float'];
  region: Scalars['String'];
  state: Scalars['String'];
  styles?: InputMaybe<Array<Scalars['String']>>;
  yardAttributes?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateGhostedConversationInput = {
  acknowledged?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type UpdateInvoiceInput = {
  applicationFeeAmount?: InputMaybe<Scalars['Int']>;
  clientNotes?: InputMaybe<Scalars['String']>;
  connectedAccountId?: InputMaybe<Scalars['String']>;
  contractorId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  estimateId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  internalNotes?: InputMaybe<Scalars['String']>;
  lastPaymentAttemptedTs?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  paymentDueTs?: InputMaybe<Scalars['DateTime']>;
  paymentMilestone?: InputMaybe<InvoicePaymentMilestone>;
  proInvoiceReference?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  receiptEmail?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['Int']>;
  voidInvoice?: InputMaybe<Scalars['Boolean']>;
  yzFeeRate?: InputMaybe<Scalars['String']>;
};

export type UpdateMailingAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdatePipefyCardResponse = {
  __typename?: 'UpdatePipefyCardResponse';
  pipefyCardId: Scalars['String'];
};

export type UpdateProfileInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type UpdateRevisionInput = {
  contractorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  shareMediaLink?: InputMaybe<Scalars['String']>;
};

export type UpdateStyleProfileInput = {
  id: Scalars['String'];
  maxBudget?: InputMaybe<Scalars['Float']>;
  timeline?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  yardLocation?: InputMaybe<YardLocation>;
};

export type UpdateTotalContractValueInput = {
  buildProjectId: Scalars['String'];
  contractorId: Scalars['String'];
  totalContractValue: Scalars['Float'];
};

export type UpdateUserEmailInput = {
  email: Scalars['String'];
  userId: Scalars['String'];
};

export type UpdateUserInput = {
  accountId: Scalars['String'];
  email: Scalars['String'];
  isLeadCapture: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type UpdateUserProfileInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  leadZipcode?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<UpdateMailingAddressInput>;
  newMailingAddress?: InputMaybe<CreateAddressInput>;
  optIn?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  testAccount?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type UpdateUserProjectElementsBatchInput = {
  buildProjectId: Scalars['String'];
  projectElements: Array<ProjectElementsBatchInput>;
};

export type UpdateUserTermsOfServiceInput = {
  dateOfAgree?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  tosAgreed?: InputMaybe<Scalars['Boolean']>;
  typeOfTos?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UploadMediaMessageInput = {
  buildProjectId?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  messageId?: InputMaybe<Scalars['String']>;
  mimeType: Scalars['String'];
  source?: InputMaybe<MediaSource>;
};

export type UpsertSurveyQuestionResponseInput = {
  response: Scalars['String'];
  surveyQuestionId: Scalars['String'];
  surveyResponseId: Scalars['String'];
};

export type UpsertSurveyQuestionResponsesInput = {
  completed?: InputMaybe<Scalars['Boolean']>;
  questionResponses: Array<UpsertSurveyQuestionResponseInput>;
  userId: Scalars['String'];
};

export type Url = {
  __typename?: 'Url';
  createdTs: Scalars['DateTime'];
  longUrl: Scalars['String'];
  requiredPath?: Maybe<Scalars['String']>;
  shortUrl: Scalars['String'];
  updatedTs: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  account: Account;
  accountId: Scalars['String'];
  hasRevisonDelivered: Scalars['Boolean'];
  id: Scalars['String'];
  isBuildStudioClient?: Maybe<Scalars['Boolean']>;
  isDesignClient: Scalars['Boolean'];
  isLeadCapture: Scalars['Boolean'];
  isProjectClient?: Maybe<Scalars['Boolean']>;
  loginHistory?: Maybe<Array<LoginHistory>>;
  mustAcceptTerms: Scalars['Boolean'];
  styleProfile?: Maybe<StyleProfile>;
  userProfile?: Maybe<UserProfile>;
};


export type UserLoginHistoryArgs = {
  pagination?: InputMaybe<LoginHistoryPaginator>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  createdTs: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  leadZipcode?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<Address>;
  mailingAddressId: Scalars['String'];
  optIn?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  testAccount?: Maybe<Scalars['Boolean']>;
  updatedTs: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type UserProfileEnhanced = {
  __typename?: 'UserProfileEnhanced';
  accountId?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isDesignClient?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  leadZipcode?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<Address>;
  mailingAddressId: Scalars['String'];
  optIn?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  testAccount?: Maybe<Scalars['Boolean']>;
  updatedTs: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type UserSearchResponse = {
  __typename?: 'UserSearchResponse';
  page: Scalars['Float'];
  pageCount: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<UserSearchResult>;
};

export type UserSearchResult = {
  __typename?: 'UserSearchResult';
  email: Scalars['String'];
  fromContractorConnect: Scalars['Boolean'];
  fromLegacy: Scalars['Boolean'];
  user?: Maybe<User>;
  userProfile?: Maybe<UserProfile>;
  user_id: Scalars['String'];
};

export enum UserSortByEnum {
  CreatedTs = 'CREATED_TS',
  UpdatedTs = 'UPDATED_TS'
}

export type UserTermsOfService = {
  __typename?: 'UserTermsOfService';
  createdTs: Scalars['DateTime'];
  dateOfAgree: Scalars['DateTime'];
  id: Scalars['String'];
  tosAgreed: Scalars['Boolean'];
  typeOfTos: Scalars['String'];
  updatedTs: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type UsersPaginator = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortBy?: InputMaybe<UserSortByEnum>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type WriteableMedia = {
  __typename?: 'WriteableMedia';
  bucketName: Scalars['String'];
  buildProjectId?: Maybe<Scalars['String']>;
  contractorId?: Maybe<Scalars['String']>;
  createdTs: Scalars['DateTime'];
  id: Scalars['String'];
  messageId?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  objectName: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  revisionId: Scalars['String'];
  source?: Maybe<MediaSource>;
  tags?: Maybe<Array<MediaTag>>;
  uploadConfirmed: Scalars['Boolean'];
  uploaderId?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
  writeUrl: Scalars['String'];
};

export enum YardDesignLocation {
  Back = 'BACK',
  Front = 'FRONT',
  Side = 'SIDE'
}

export enum YardElement {
  BasketballCourt = 'BASKETBALL_COURT',
  Deck = 'DECK',
  Driveway = 'DRIVEWAY',
  Fence = 'FENCE',
  FirePit = 'FIRE_PIT',
  FirePlace = 'FIRE_PLACE',
  Grass = 'GRASS',
  Grill = 'GRILL',
  Hammock = 'HAMMOCK',
  HotTub = 'HOT_TUB',
  OutdoorKitchen = 'OUTDOOR_KITCHEN',
  Patio = 'PATIO',
  Pergola = 'PERGOLA',
  Planters = 'PLANTERS',
  Playground = 'PLAYGROUND',
  Pool = 'POOL',
  PoolHouse = 'POOL_HOUSE',
  RopeLights = 'ROPE_LIGHTS',
  Slope = 'SLOPE',
  Walkway = 'WALKWAY',
  WaterFountain = 'WATER_FOUNTAIN'
}

export enum YardLocation {
  BackYard = 'BACK_YARD',
  FrontYard = 'FRONT_YARD',
  FullYard = 'FULL_YARD',
  NotSure = 'NOT_SURE'
}

export type GetConversationsForTopNavQueryVariables = Exact<{
  input: FindAllConversationsInput;
}>;


export type GetConversationsForTopNavQuery = { __typename?: 'Query', conversations: Array<{ __typename?: 'Conversation', id: string, sid: string }> };

export type GetConversationDetailsQueryVariables = Exact<{
  input: FindOneConversationInput;
}>;


export type GetConversationDetailsQuery = { __typename?: 'Query', conversation: { __typename?: 'Conversation', sid: string, conversationParticipants: Array<{ __typename?: 'ConversationParticipant', name?: string | null, role: ConversationRole, twilioId?: string | null, logoUrl?: string | null }> } };

export type GetUnreadMessageCountQueryVariables = Exact<{
  input: GetUnreadMessagesInput;
}>;


export type GetUnreadMessageCountQuery = { __typename?: 'Query', unreadMessagesCountByConversationParticipant: number };

export type UpdateConversationMutationVariables = Exact<{
  input: UpdateConversationInput;
}>;


export type UpdateConversationMutation = { __typename?: 'Mutation', updateConversation: { __typename?: 'Conversation', id: string } };

export type GetLatestDeliveredeRevisionQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetLatestDeliveredeRevisionQuery = { __typename?: 'Query', latestDeliveredRevision?: { __typename?: 'LatestRevision', revisionId?: string | null } | null };

export type CreateDesignOnboardingMutationVariables = Exact<{
  input: CreateDesignOnboardingInput;
}>;


export type CreateDesignOnboardingMutation = { __typename?: 'Mutation', createDesignOnboarding: { __typename?: 'DesignOnboarding', id: string } };

export type UpdateDesignOnboardingMutationVariables = Exact<{
  input: UpdateDesignOnboardingInput;
}>;


export type UpdateDesignOnboardingMutation = { __typename?: 'Mutation', updateDesignOnboarding: { __typename?: 'DesignOnboarding', id: string } };

export type GetDesignOnboardingQueryVariables = Exact<{
  input: FindOneDesignOnboardingInput;
}>;


export type GetDesignOnboardingQuery = { __typename?: 'Query', designOnboarding?: { __typename?: 'DesignOnboarding', id: string, buildProjectId: string, dimensionsWidth: number, dimensionsLength: number, totalSquareFootage: number, additionalNotes?: string | null, inspirationLinks?: Array<string> | null, propertyPhotos: Array<{ __typename?: 'Media', id: string, uri?: string | null }>, inspirationPhotos: Array<{ __typename?: 'Media', id: string, uri?: string | null }> } | null };

export type UploadMediaMessageMutationVariables = Exact<{
  input: UploadMediaMessageInput;
}>;


export type UploadMediaMessageMutation = { __typename?: 'Mutation', uploadMediaMessage: { __typename?: 'WriteableMedia', id: string, writeUrl: string } };

export type SendMessageForDesignOnboardMutationVariables = Exact<{
  input: DesignOnboardMessageSubmissionInput;
}>;


export type SendMessageForDesignOnboardMutation = { __typename?: 'Mutation', designOnboardMessageSubmission: { __typename?: 'Message', id: string } };

export type GetBuildProjectElementsQueryVariables = Exact<{
  input: FindBuildProjectInput;
}>;


export type GetBuildProjectElementsQuery = { __typename?: 'Query', buildProject: { __typename?: 'BuildProject', projectElements: Array<{ __typename?: 'ProjectElement', id: string, name: string, category: string }> } };

export type SyncUserToCordialMutationVariables = Exact<{
  input: SyncUserToCordialInput;
}>;


export type SyncUserToCordialMutation = { __typename?: 'Mutation', syncUserToCordial: { __typename?: 'SyncUserToCordialResponse', email: string } };

export type UserProfileSearchQueryVariables = Exact<{
  input: SearchUserProfilesInput;
}>;


export type UserProfileSearchQuery = { __typename?: 'Query', searchUserProfiles?: Array<{ __typename?: 'UserProfile', userId: string, firstName?: string | null, lastName?: string | null, email?: string | null, mailingAddress?: { __typename?: 'Address', addressLine1: string, addressLine2?: string | null, city: string, state: string, zipcode: string } | null }> | null };

export type FindUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindUserQuery = { __typename?: 'Query', findUser: { __typename?: 'User', accountId: string, isBuildStudioClient?: boolean | null, userProfile?: { __typename?: 'UserProfile', userId: string, email?: string | null, firstName?: string | null, lastName?: string | null, mailingAddress?: { __typename?: 'Address', addressLine1: string, addressLine2?: string | null, city: string, state: string, zipcode: string } | null } | null } };

export type GetUnreadMessagesCountQueryVariables = Exact<{
  input: GetUnreadMessagesInput;
}>;


export type GetUnreadMessagesCountQuery = { __typename?: 'Query', unreadMessagesCountByConversationParticipant: number };

export type UpdateStyleProfileMutationVariables = Exact<{
  input: UpdateStyleProfileInput;
}>;


export type UpdateStyleProfileMutation = { __typename?: 'Mutation', updateStyleProfile: { __typename?: 'StyleProfile', id: string } };

export type UpdateBuildProjectMutationVariables = Exact<{
  input: UpdateBuildProjectInput;
}>;


export type UpdateBuildProjectMutation = { __typename?: 'Mutation', updateBuildProject: { __typename?: 'BuildProject', id: string } };

export type GetSurveyQueryVariables = Exact<{
  input: FindSurveyInput;
  surveyQuestionsInput?: InputMaybe<SurveyQuestionsInput>;
}>;


export type GetSurveyQuery = { __typename?: 'Query', survey: { __typename?: 'Survey', id: string, surveyQuestions: Array<{ __typename?: 'SurveyQuestion', id: string, questionKey: string, text: string, section: number, index: number, format: string, required: boolean }> } };

export type GetProfileDataForViewProjectQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProfileDataForViewProjectQuery = { __typename?: 'Query', findUser: { __typename?: 'User', account: { __typename?: 'Account', id: string, buildProjects: Array<{ __typename?: 'BuildProject', id: string, projectBudgetEstimate?: Array<number> | null, internalNotes?: string | null, proFacingNotes?: string | null, accountManagerId?: string | null, pipefyClientCardId?: string | null, designAssignment?: { __typename?: 'DesignAssignment', status: string, info: string, designPackage: string } | null, projectElements: Array<{ __typename?: 'ProjectElement', id: string, name: string, category: string, budgetLowerBound?: number | null, budgetUpperBound?: number | null, notes?: string | null }>, surveyResponses: Array<{ __typename?: 'SurveyResponse', id: string, surveyId: string, surveyQuestionReponses: Array<{ __typename?: 'SurveyQuestionResponse', response: string, surveyQuestionId: string, createdTs: any, updatedTs?: any | null }> }> }> }, styleProfile?: { __typename?: 'StyleProfile', id: string, styleResults: Array<{ __typename?: 'StyleResult', styleName: string, percentage: number, imageUrl: string, styleDescription: string }> } | null, userProfile?: { __typename?: 'UserProfile', email?: string | null, phone?: string | null, firstName?: string | null, lastName?: string | null, mailingAddress?: { __typename?: 'Address', addressLine1: string, addressLine2?: string | null, city: string, state: string, zipcode: string } | null } | null } };

export type GetShareableLinkFromProfileQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetShareableLinkFromProfileQuery = { __typename?: 'Query', profile?: { __typename?: 'Profile', id: string, shareableLink?: { __typename?: 'ShareableLink', id: string, media?: Array<{ __typename?: 'MediaPangaea', id: string }> | null } | null } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile: { __typename?: 'UserProfile', userId: string, email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, mailingAddress?: { __typename?: 'Address', addressLine1: string, addressLine2?: string | null, city: string, state: string, zipcode: string, latitude: number, longitude: number } | null } };

export type SendMessageForSignUpDialogMutationVariables = Exact<{
  input: SignUpAndSendMessageSubmissionInput;
}>;


export type SendMessageForSignUpDialogMutation = { __typename?: 'Mutation', signUpAndSendMessageSubmission: { __typename?: 'Message', id: string } };

export type StartLeadConvertedConversationsMutationVariables = Exact<{
  input: StartLeadConversationsInput;
}>;


export type StartLeadConvertedConversationsMutation = { __typename?: 'Mutation', startLeadConvertedConversations?: Array<{ __typename?: 'Conversation', id: string } | null> | null };

export type CreateCurrentUserTermsOfServiceMutationVariables = Exact<{
  input: CreateCurrentUserTermsOfServiceInput;
}>;


export type CreateCurrentUserTermsOfServiceMutation = { __typename?: 'Mutation', createCurrentUserTermsOfService: { __typename?: 'UserTermsOfService', id: string } };

export type VoidInvoiceMutationVariables = Exact<{
  input: UpdateInvoiceInput;
}>;


export type VoidInvoiceMutation = { __typename?: 'Mutation', updateInvoice?: { __typename?: 'Invoice', id: string } | null };


export const GetConversationsForTopNavDocument = gql`
    query GetConversationsForTopNav($input: FindAllConversationsInput!) {
  conversations(input: $input) {
    id
    sid
  }
}
    `;

/**
 * __useGetConversationsForTopNavQuery__
 *
 * To run a query within a React component, call `useGetConversationsForTopNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsForTopNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsForTopNavQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetConversationsForTopNavQuery(baseOptions: Apollo.QueryHookOptions<GetConversationsForTopNavQuery, GetConversationsForTopNavQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationsForTopNavQuery, GetConversationsForTopNavQueryVariables>(GetConversationsForTopNavDocument, options);
      }
export function useGetConversationsForTopNavLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationsForTopNavQuery, GetConversationsForTopNavQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationsForTopNavQuery, GetConversationsForTopNavQueryVariables>(GetConversationsForTopNavDocument, options);
        }
export type GetConversationsForTopNavQueryHookResult = ReturnType<typeof useGetConversationsForTopNavQuery>;
export type GetConversationsForTopNavLazyQueryHookResult = ReturnType<typeof useGetConversationsForTopNavLazyQuery>;
export type GetConversationsForTopNavQueryResult = Apollo.QueryResult<GetConversationsForTopNavQuery, GetConversationsForTopNavQueryVariables>;
export const GetConversationDetailsDocument = gql`
    query GetConversationDetails($input: FindOneConversationInput!) {
  conversation(input: $input) {
    conversationParticipants {
      name
      role
      twilioId
      logoUrl
    }
    sid
  }
}
    `;

/**
 * __useGetConversationDetailsQuery__
 *
 * To run a query within a React component, call `useGetConversationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetConversationDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetConversationDetailsQuery, GetConversationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationDetailsQuery, GetConversationDetailsQueryVariables>(GetConversationDetailsDocument, options);
      }
export function useGetConversationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationDetailsQuery, GetConversationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationDetailsQuery, GetConversationDetailsQueryVariables>(GetConversationDetailsDocument, options);
        }
export type GetConversationDetailsQueryHookResult = ReturnType<typeof useGetConversationDetailsQuery>;
export type GetConversationDetailsLazyQueryHookResult = ReturnType<typeof useGetConversationDetailsLazyQuery>;
export type GetConversationDetailsQueryResult = Apollo.QueryResult<GetConversationDetailsQuery, GetConversationDetailsQueryVariables>;
export const GetUnreadMessageCountDocument = gql`
    query GetUnreadMessageCount($input: GetUnreadMessagesInput!) {
  unreadMessagesCountByConversationParticipant(input: $input)
}
    `;

/**
 * __useGetUnreadMessageCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadMessageCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUnreadMessageCountQuery(baseOptions: Apollo.QueryHookOptions<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>(GetUnreadMessageCountDocument, options);
      }
export function useGetUnreadMessageCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>(GetUnreadMessageCountDocument, options);
        }
export type GetUnreadMessageCountQueryHookResult = ReturnType<typeof useGetUnreadMessageCountQuery>;
export type GetUnreadMessageCountLazyQueryHookResult = ReturnType<typeof useGetUnreadMessageCountLazyQuery>;
export type GetUnreadMessageCountQueryResult = Apollo.QueryResult<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>;
export const UpdateConversationDocument = gql`
    mutation UpdateConversation($input: UpdateConversationInput!) {
  updateConversation(input: $input) {
    id
  }
}
    `;
export type UpdateConversationMutationFn = Apollo.MutationFunction<UpdateConversationMutation, UpdateConversationMutationVariables>;

/**
 * __useUpdateConversationMutation__
 *
 * To run a mutation, you first call `useUpdateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversationMutation, { data, loading, error }] = useUpdateConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConversationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConversationMutation, UpdateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConversationMutation, UpdateConversationMutationVariables>(UpdateConversationDocument, options);
      }
export type UpdateConversationMutationHookResult = ReturnType<typeof useUpdateConversationMutation>;
export type UpdateConversationMutationResult = Apollo.MutationResult<UpdateConversationMutation>;
export type UpdateConversationMutationOptions = Apollo.BaseMutationOptions<UpdateConversationMutation, UpdateConversationMutationVariables>;
export const GetLatestDeliveredeRevisionDocument = gql`
    query GetLatestDeliveredeRevision($userId: String!) {
  latestDeliveredRevision(userId: $userId) {
    revisionId
  }
}
    `;

/**
 * __useGetLatestDeliveredeRevisionQuery__
 *
 * To run a query within a React component, call `useGetLatestDeliveredeRevisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDeliveredeRevisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestDeliveredeRevisionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLatestDeliveredeRevisionQuery(baseOptions: Apollo.QueryHookOptions<GetLatestDeliveredeRevisionQuery, GetLatestDeliveredeRevisionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestDeliveredeRevisionQuery, GetLatestDeliveredeRevisionQueryVariables>(GetLatestDeliveredeRevisionDocument, options);
      }
export function useGetLatestDeliveredeRevisionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestDeliveredeRevisionQuery, GetLatestDeliveredeRevisionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestDeliveredeRevisionQuery, GetLatestDeliveredeRevisionQueryVariables>(GetLatestDeliveredeRevisionDocument, options);
        }
export type GetLatestDeliveredeRevisionQueryHookResult = ReturnType<typeof useGetLatestDeliveredeRevisionQuery>;
export type GetLatestDeliveredeRevisionLazyQueryHookResult = ReturnType<typeof useGetLatestDeliveredeRevisionLazyQuery>;
export type GetLatestDeliveredeRevisionQueryResult = Apollo.QueryResult<GetLatestDeliveredeRevisionQuery, GetLatestDeliveredeRevisionQueryVariables>;
export const CreateDesignOnboardingDocument = gql`
    mutation CreateDesignOnboarding($input: CreateDesignOnboardingInput!) {
  createDesignOnboarding(input: $input) {
    id
  }
}
    `;
export type CreateDesignOnboardingMutationFn = Apollo.MutationFunction<CreateDesignOnboardingMutation, CreateDesignOnboardingMutationVariables>;

/**
 * __useCreateDesignOnboardingMutation__
 *
 * To run a mutation, you first call `useCreateDesignOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDesignOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDesignOnboardingMutation, { data, loading, error }] = useCreateDesignOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDesignOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<CreateDesignOnboardingMutation, CreateDesignOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDesignOnboardingMutation, CreateDesignOnboardingMutationVariables>(CreateDesignOnboardingDocument, options);
      }
export type CreateDesignOnboardingMutationHookResult = ReturnType<typeof useCreateDesignOnboardingMutation>;
export type CreateDesignOnboardingMutationResult = Apollo.MutationResult<CreateDesignOnboardingMutation>;
export type CreateDesignOnboardingMutationOptions = Apollo.BaseMutationOptions<CreateDesignOnboardingMutation, CreateDesignOnboardingMutationVariables>;
export const UpdateDesignOnboardingDocument = gql`
    mutation UpdateDesignOnboarding($input: UpdateDesignOnboardingInput!) {
  updateDesignOnboarding(input: $input) {
    id
  }
}
    `;
export type UpdateDesignOnboardingMutationFn = Apollo.MutationFunction<UpdateDesignOnboardingMutation, UpdateDesignOnboardingMutationVariables>;

/**
 * __useUpdateDesignOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateDesignOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDesignOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDesignOnboardingMutation, { data, loading, error }] = useUpdateDesignOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDesignOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDesignOnboardingMutation, UpdateDesignOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDesignOnboardingMutation, UpdateDesignOnboardingMutationVariables>(UpdateDesignOnboardingDocument, options);
      }
export type UpdateDesignOnboardingMutationHookResult = ReturnType<typeof useUpdateDesignOnboardingMutation>;
export type UpdateDesignOnboardingMutationResult = Apollo.MutationResult<UpdateDesignOnboardingMutation>;
export type UpdateDesignOnboardingMutationOptions = Apollo.BaseMutationOptions<UpdateDesignOnboardingMutation, UpdateDesignOnboardingMutationVariables>;
export const GetDesignOnboardingDocument = gql`
    query GetDesignOnboarding($input: FindOneDesignOnboardingInput!) {
  designOnboarding(input: $input) {
    id
    buildProjectId
    dimensionsWidth
    dimensionsLength
    totalSquareFootage
    additionalNotes
    inspirationLinks
    propertyPhotos {
      id
      uri
    }
    inspirationPhotos {
      id
      uri
    }
  }
}
    `;

/**
 * __useGetDesignOnboardingQuery__
 *
 * To run a query within a React component, call `useGetDesignOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDesignOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDesignOnboardingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDesignOnboardingQuery(baseOptions: Apollo.QueryHookOptions<GetDesignOnboardingQuery, GetDesignOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDesignOnboardingQuery, GetDesignOnboardingQueryVariables>(GetDesignOnboardingDocument, options);
      }
export function useGetDesignOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDesignOnboardingQuery, GetDesignOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDesignOnboardingQuery, GetDesignOnboardingQueryVariables>(GetDesignOnboardingDocument, options);
        }
export type GetDesignOnboardingQueryHookResult = ReturnType<typeof useGetDesignOnboardingQuery>;
export type GetDesignOnboardingLazyQueryHookResult = ReturnType<typeof useGetDesignOnboardingLazyQuery>;
export type GetDesignOnboardingQueryResult = Apollo.QueryResult<GetDesignOnboardingQuery, GetDesignOnboardingQueryVariables>;
export const UploadMediaMessageDocument = gql`
    mutation UploadMediaMessage($input: UploadMediaMessageInput!) {
  uploadMediaMessage(input: $input) {
    id
    writeUrl
  }
}
    `;
export type UploadMediaMessageMutationFn = Apollo.MutationFunction<UploadMediaMessageMutation, UploadMediaMessageMutationVariables>;

/**
 * __useUploadMediaMessageMutation__
 *
 * To run a mutation, you first call `useUploadMediaMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaMessageMutation, { data, loading, error }] = useUploadMediaMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadMediaMessageMutation(baseOptions?: Apollo.MutationHookOptions<UploadMediaMessageMutation, UploadMediaMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMediaMessageMutation, UploadMediaMessageMutationVariables>(UploadMediaMessageDocument, options);
      }
export type UploadMediaMessageMutationHookResult = ReturnType<typeof useUploadMediaMessageMutation>;
export type UploadMediaMessageMutationResult = Apollo.MutationResult<UploadMediaMessageMutation>;
export type UploadMediaMessageMutationOptions = Apollo.BaseMutationOptions<UploadMediaMessageMutation, UploadMediaMessageMutationVariables>;
export const SendMessageForDesignOnboardDocument = gql`
    mutation sendMessageForDesignOnboard($input: DesignOnboardMessageSubmissionInput!) {
  designOnboardMessageSubmission(input: $input) {
    id
  }
}
    `;
export type SendMessageForDesignOnboardMutationFn = Apollo.MutationFunction<SendMessageForDesignOnboardMutation, SendMessageForDesignOnboardMutationVariables>;

/**
 * __useSendMessageForDesignOnboardMutation__
 *
 * To run a mutation, you first call `useSendMessageForDesignOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageForDesignOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageForDesignOnboardMutation, { data, loading, error }] = useSendMessageForDesignOnboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageForDesignOnboardMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageForDesignOnboardMutation, SendMessageForDesignOnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageForDesignOnboardMutation, SendMessageForDesignOnboardMutationVariables>(SendMessageForDesignOnboardDocument, options);
      }
export type SendMessageForDesignOnboardMutationHookResult = ReturnType<typeof useSendMessageForDesignOnboardMutation>;
export type SendMessageForDesignOnboardMutationResult = Apollo.MutationResult<SendMessageForDesignOnboardMutation>;
export type SendMessageForDesignOnboardMutationOptions = Apollo.BaseMutationOptions<SendMessageForDesignOnboardMutation, SendMessageForDesignOnboardMutationVariables>;
export const GetBuildProjectElementsDocument = gql`
    query GetBuildProjectElements($input: FindBuildProjectInput!) {
  buildProject(input: $input) {
    projectElements {
      id
      name
      category
    }
  }
}
    `;

/**
 * __useGetBuildProjectElementsQuery__
 *
 * To run a query within a React component, call `useGetBuildProjectElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildProjectElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildProjectElementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBuildProjectElementsQuery(baseOptions: Apollo.QueryHookOptions<GetBuildProjectElementsQuery, GetBuildProjectElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildProjectElementsQuery, GetBuildProjectElementsQueryVariables>(GetBuildProjectElementsDocument, options);
      }
export function useGetBuildProjectElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildProjectElementsQuery, GetBuildProjectElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildProjectElementsQuery, GetBuildProjectElementsQueryVariables>(GetBuildProjectElementsDocument, options);
        }
export type GetBuildProjectElementsQueryHookResult = ReturnType<typeof useGetBuildProjectElementsQuery>;
export type GetBuildProjectElementsLazyQueryHookResult = ReturnType<typeof useGetBuildProjectElementsLazyQuery>;
export type GetBuildProjectElementsQueryResult = Apollo.QueryResult<GetBuildProjectElementsQuery, GetBuildProjectElementsQueryVariables>;
export const SyncUserToCordialDocument = gql`
    mutation SyncUserToCordial($input: SyncUserToCordialInput!) {
  syncUserToCordial(input: $input) {
    email
  }
}
    `;
export type SyncUserToCordialMutationFn = Apollo.MutationFunction<SyncUserToCordialMutation, SyncUserToCordialMutationVariables>;

/**
 * __useSyncUserToCordialMutation__
 *
 * To run a mutation, you first call `useSyncUserToCordialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUserToCordialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUserToCordialMutation, { data, loading, error }] = useSyncUserToCordialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncUserToCordialMutation(baseOptions?: Apollo.MutationHookOptions<SyncUserToCordialMutation, SyncUserToCordialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncUserToCordialMutation, SyncUserToCordialMutationVariables>(SyncUserToCordialDocument, options);
      }
export type SyncUserToCordialMutationHookResult = ReturnType<typeof useSyncUserToCordialMutation>;
export type SyncUserToCordialMutationResult = Apollo.MutationResult<SyncUserToCordialMutation>;
export type SyncUserToCordialMutationOptions = Apollo.BaseMutationOptions<SyncUserToCordialMutation, SyncUserToCordialMutationVariables>;
export const UserProfileSearchDocument = gql`
    query UserProfileSearch($input: SearchUserProfilesInput!) {
  searchUserProfiles(input: $input) {
    userId
    firstName
    lastName
    email
    mailingAddress {
      addressLine1
      addressLine2
      city
      state
      zipcode
    }
  }
}
    `;

/**
 * __useUserProfileSearchQuery__
 *
 * To run a query within a React component, call `useUserProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserProfileSearchQuery(baseOptions: Apollo.QueryHookOptions<UserProfileSearchQuery, UserProfileSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileSearchQuery, UserProfileSearchQueryVariables>(UserProfileSearchDocument, options);
      }
export function useUserProfileSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileSearchQuery, UserProfileSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileSearchQuery, UserProfileSearchQueryVariables>(UserProfileSearchDocument, options);
        }
export type UserProfileSearchQueryHookResult = ReturnType<typeof useUserProfileSearchQuery>;
export type UserProfileSearchLazyQueryHookResult = ReturnType<typeof useUserProfileSearchLazyQuery>;
export type UserProfileSearchQueryResult = Apollo.QueryResult<UserProfileSearchQuery, UserProfileSearchQueryVariables>;
export const FindUserDocument = gql`
    query FindUser($id: String!) {
  findUser(id: $id) {
    accountId
    isBuildStudioClient
    userProfile {
      userId
      email
      firstName
      lastName
      mailingAddress {
        addressLine1
        addressLine2
        city
        state
        zipcode
      }
    }
  }
}
    `;

/**
 * __useFindUserQuery__
 *
 * To run a query within a React component, call `useFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserQuery(baseOptions: Apollo.QueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
      }
export function useFindUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserQuery, FindUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
        }
export type FindUserQueryHookResult = ReturnType<typeof useFindUserQuery>;
export type FindUserLazyQueryHookResult = ReturnType<typeof useFindUserLazyQuery>;
export type FindUserQueryResult = Apollo.QueryResult<FindUserQuery, FindUserQueryVariables>;
export const GetUnreadMessagesCountDocument = gql`
    query GetUnreadMessagesCount($input: GetUnreadMessagesInput!) {
  unreadMessagesCountByConversationParticipant(input: $input)
}
    `;

/**
 * __useGetUnreadMessagesCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadMessagesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUnreadMessagesCountQuery(baseOptions: Apollo.QueryHookOptions<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>(GetUnreadMessagesCountDocument, options);
      }
export function useGetUnreadMessagesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>(GetUnreadMessagesCountDocument, options);
        }
export type GetUnreadMessagesCountQueryHookResult = ReturnType<typeof useGetUnreadMessagesCountQuery>;
export type GetUnreadMessagesCountLazyQueryHookResult = ReturnType<typeof useGetUnreadMessagesCountLazyQuery>;
export type GetUnreadMessagesCountQueryResult = Apollo.QueryResult<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>;
export const UpdateStyleProfileDocument = gql`
    mutation UpdateStyleProfile($input: UpdateStyleProfileInput!) {
  updateStyleProfile(input: $input) {
    id
  }
}
    `;
export type UpdateStyleProfileMutationFn = Apollo.MutationFunction<UpdateStyleProfileMutation, UpdateStyleProfileMutationVariables>;

/**
 * __useUpdateStyleProfileMutation__
 *
 * To run a mutation, you first call `useUpdateStyleProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStyleProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStyleProfileMutation, { data, loading, error }] = useUpdateStyleProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStyleProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStyleProfileMutation, UpdateStyleProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStyleProfileMutation, UpdateStyleProfileMutationVariables>(UpdateStyleProfileDocument, options);
      }
export type UpdateStyleProfileMutationHookResult = ReturnType<typeof useUpdateStyleProfileMutation>;
export type UpdateStyleProfileMutationResult = Apollo.MutationResult<UpdateStyleProfileMutation>;
export type UpdateStyleProfileMutationOptions = Apollo.BaseMutationOptions<UpdateStyleProfileMutation, UpdateStyleProfileMutationVariables>;
export const UpdateBuildProjectDocument = gql`
    mutation UpdateBuildProject($input: UpdateBuildProjectInput!) {
  updateBuildProject(input: $input) {
    id
  }
}
    `;
export type UpdateBuildProjectMutationFn = Apollo.MutationFunction<UpdateBuildProjectMutation, UpdateBuildProjectMutationVariables>;

/**
 * __useUpdateBuildProjectMutation__
 *
 * To run a mutation, you first call `useUpdateBuildProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildProjectMutation, { data, loading, error }] = useUpdateBuildProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBuildProjectMutation, UpdateBuildProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBuildProjectMutation, UpdateBuildProjectMutationVariables>(UpdateBuildProjectDocument, options);
      }
export type UpdateBuildProjectMutationHookResult = ReturnType<typeof useUpdateBuildProjectMutation>;
export type UpdateBuildProjectMutationResult = Apollo.MutationResult<UpdateBuildProjectMutation>;
export type UpdateBuildProjectMutationOptions = Apollo.BaseMutationOptions<UpdateBuildProjectMutation, UpdateBuildProjectMutationVariables>;
export const GetSurveyDocument = gql`
    query GetSurvey($input: FindSurveyInput!, $surveyQuestionsInput: SurveyQuestionsInput) {
  survey(input: $input) {
    id
    surveyQuestions(input: $surveyQuestionsInput) {
      id
      questionKey
      text
      section
      index
      format
      required
    }
  }
}
    `;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      input: // value for 'input'
 *      surveyQuestionsInput: // value for 'surveyQuestionsInput'
 *   },
 * });
 */
export function useGetSurveyQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
      }
export function useGetSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
        }
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<typeof useGetSurveyLazyQuery>;
export type GetSurveyQueryResult = Apollo.QueryResult<GetSurveyQuery, GetSurveyQueryVariables>;
export const GetProfileDataForViewProjectDocument = gql`
    query GetProfileDataForViewProject($id: String!) {
  findUser(id: $id) {
    account {
      id
      buildProjects {
        id
        projectBudgetEstimate
        internalNotes
        proFacingNotes
        accountManagerId
        designAssignment {
          status
          info
          designPackage
        }
        projectElements {
          id
          name
          category
          budgetLowerBound
          budgetUpperBound
          notes
        }
        surveyResponses {
          id
          surveyId
          surveyQuestionReponses {
            response
            surveyQuestionId
            createdTs
            updatedTs
          }
        }
        pipefyClientCardId
      }
    }
    styleProfile {
      id
      styleResults {
        styleName
        percentage
        imageUrl
        styleDescription
      }
    }
    userProfile {
      email
      phone
      firstName
      lastName
      mailingAddress {
        addressLine1
        addressLine2
        city
        state
        zipcode
      }
    }
  }
}
    `;

/**
 * __useGetProfileDataForViewProjectQuery__
 *
 * To run a query within a React component, call `useGetProfileDataForViewProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileDataForViewProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileDataForViewProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileDataForViewProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProfileDataForViewProjectQuery, GetProfileDataForViewProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileDataForViewProjectQuery, GetProfileDataForViewProjectQueryVariables>(GetProfileDataForViewProjectDocument, options);
      }
export function useGetProfileDataForViewProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileDataForViewProjectQuery, GetProfileDataForViewProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileDataForViewProjectQuery, GetProfileDataForViewProjectQueryVariables>(GetProfileDataForViewProjectDocument, options);
        }
export type GetProfileDataForViewProjectQueryHookResult = ReturnType<typeof useGetProfileDataForViewProjectQuery>;
export type GetProfileDataForViewProjectLazyQueryHookResult = ReturnType<typeof useGetProfileDataForViewProjectLazyQuery>;
export type GetProfileDataForViewProjectQueryResult = Apollo.QueryResult<GetProfileDataForViewProjectQuery, GetProfileDataForViewProjectQueryVariables>;
export const GetShareableLinkFromProfileDocument = gql`
    query GetShareableLinkFromProfile($id: String!) {
  profile(id: $id) {
    id
    shareableLink {
      id
      media {
        id
      }
    }
  }
}
    `;

/**
 * __useGetShareableLinkFromProfileQuery__
 *
 * To run a query within a React component, call `useGetShareableLinkFromProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareableLinkFromProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareableLinkFromProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShareableLinkFromProfileQuery(baseOptions: Apollo.QueryHookOptions<GetShareableLinkFromProfileQuery, GetShareableLinkFromProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShareableLinkFromProfileQuery, GetShareableLinkFromProfileQueryVariables>(GetShareableLinkFromProfileDocument, options);
      }
export function useGetShareableLinkFromProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShareableLinkFromProfileQuery, GetShareableLinkFromProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShareableLinkFromProfileQuery, GetShareableLinkFromProfileQueryVariables>(GetShareableLinkFromProfileDocument, options);
        }
export type GetShareableLinkFromProfileQueryHookResult = ReturnType<typeof useGetShareableLinkFromProfileQuery>;
export type GetShareableLinkFromProfileLazyQueryHookResult = ReturnType<typeof useGetShareableLinkFromProfileLazyQuery>;
export type GetShareableLinkFromProfileQueryResult = Apollo.QueryResult<GetShareableLinkFromProfileQuery, GetShareableLinkFromProfileQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    userId
    email
    firstName
    lastName
    mailingAddress {
      addressLine1
      addressLine2
      city
      state
      zipcode
      latitude
      longitude
    }
    phone
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const SendMessageForSignUpDialogDocument = gql`
    mutation sendMessageForSignUpDialog($input: SignUpAndSendMessageSubmissionInput!) {
  signUpAndSendMessageSubmission(input: $input) {
    id
  }
}
    `;
export type SendMessageForSignUpDialogMutationFn = Apollo.MutationFunction<SendMessageForSignUpDialogMutation, SendMessageForSignUpDialogMutationVariables>;

/**
 * __useSendMessageForSignUpDialogMutation__
 *
 * To run a mutation, you first call `useSendMessageForSignUpDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageForSignUpDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageForSignUpDialogMutation, { data, loading, error }] = useSendMessageForSignUpDialogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageForSignUpDialogMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageForSignUpDialogMutation, SendMessageForSignUpDialogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageForSignUpDialogMutation, SendMessageForSignUpDialogMutationVariables>(SendMessageForSignUpDialogDocument, options);
      }
export type SendMessageForSignUpDialogMutationHookResult = ReturnType<typeof useSendMessageForSignUpDialogMutation>;
export type SendMessageForSignUpDialogMutationResult = Apollo.MutationResult<SendMessageForSignUpDialogMutation>;
export type SendMessageForSignUpDialogMutationOptions = Apollo.BaseMutationOptions<SendMessageForSignUpDialogMutation, SendMessageForSignUpDialogMutationVariables>;
export const StartLeadConvertedConversationsDocument = gql`
    mutation startLeadConvertedConversations($input: StartLeadConversationsInput!) {
  startLeadConvertedConversations(input: $input) {
    id
  }
}
    `;
export type StartLeadConvertedConversationsMutationFn = Apollo.MutationFunction<StartLeadConvertedConversationsMutation, StartLeadConvertedConversationsMutationVariables>;

/**
 * __useStartLeadConvertedConversationsMutation__
 *
 * To run a mutation, you first call `useStartLeadConvertedConversationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLeadConvertedConversationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLeadConvertedConversationsMutation, { data, loading, error }] = useStartLeadConvertedConversationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartLeadConvertedConversationsMutation(baseOptions?: Apollo.MutationHookOptions<StartLeadConvertedConversationsMutation, StartLeadConvertedConversationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartLeadConvertedConversationsMutation, StartLeadConvertedConversationsMutationVariables>(StartLeadConvertedConversationsDocument, options);
      }
export type StartLeadConvertedConversationsMutationHookResult = ReturnType<typeof useStartLeadConvertedConversationsMutation>;
export type StartLeadConvertedConversationsMutationResult = Apollo.MutationResult<StartLeadConvertedConversationsMutation>;
export type StartLeadConvertedConversationsMutationOptions = Apollo.BaseMutationOptions<StartLeadConvertedConversationsMutation, StartLeadConvertedConversationsMutationVariables>;
export const CreateCurrentUserTermsOfServiceDocument = gql`
    mutation CreateCurrentUserTermsOfService($input: CreateCurrentUserTermsOfServiceInput!) {
  createCurrentUserTermsOfService(input: $input) {
    id
  }
}
    `;
export type CreateCurrentUserTermsOfServiceMutationFn = Apollo.MutationFunction<CreateCurrentUserTermsOfServiceMutation, CreateCurrentUserTermsOfServiceMutationVariables>;

/**
 * __useCreateCurrentUserTermsOfServiceMutation__
 *
 * To run a mutation, you first call `useCreateCurrentUserTermsOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrentUserTermsOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrentUserTermsOfServiceMutation, { data, loading, error }] = useCreateCurrentUserTermsOfServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrentUserTermsOfServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrentUserTermsOfServiceMutation, CreateCurrentUserTermsOfServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrentUserTermsOfServiceMutation, CreateCurrentUserTermsOfServiceMutationVariables>(CreateCurrentUserTermsOfServiceDocument, options);
      }
export type CreateCurrentUserTermsOfServiceMutationHookResult = ReturnType<typeof useCreateCurrentUserTermsOfServiceMutation>;
export type CreateCurrentUserTermsOfServiceMutationResult = Apollo.MutationResult<CreateCurrentUserTermsOfServiceMutation>;
export type CreateCurrentUserTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<CreateCurrentUserTermsOfServiceMutation, CreateCurrentUserTermsOfServiceMutationVariables>;
export const VoidInvoiceDocument = gql`
    mutation VoidInvoice($input: UpdateInvoiceInput!) {
  updateInvoice(input: $input) {
    id
  }
}
    `;
export type VoidInvoiceMutationFn = Apollo.MutationFunction<VoidInvoiceMutation, VoidInvoiceMutationVariables>;

/**
 * __useVoidInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidInvoiceMutation, { data, loading, error }] = useVoidInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidInvoiceMutation, VoidInvoiceMutationVariables>(VoidInvoiceDocument, options);
      }
export type VoidInvoiceMutationHookResult = ReturnType<typeof useVoidInvoiceMutation>;
export type VoidInvoiceMutationResult = Apollo.MutationResult<VoidInvoiceMutation>;
export type VoidInvoiceMutationOptions = Apollo.BaseMutationOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>;